import React, { useState } from 'react';
import { Modal, Box, Typography, Link, IconButton, TextField, Button, Divider } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EditIcon from '@mui/icons-material/Edit';

// Generate WhatsApp chat link
const openWhatsAppChat = (phoneNumber) => {
  return `https://wa.me/+2${phoneNumber}`;

};

const ContactModal = ({ contactModalOpen, handleContactClose, selectedSchool, handleContactEdit, handleEditSave }) => {
  const [editingContact, setEditingContact] = useState(null);
  const [contactName, setContactName] = useState('');
  const [contactPhone, setContactPhone] = useState('');

  const handleEditClick = (contact) => {
    setEditingContact(contact);
    setContactName(contact.name);
    setContactPhone(contact.phone);
  };

  const handleSaveClick = () => {
    if (editingContact) {
      handleContactEdit({
        oldPhone: editingContact.phone,
        name: contactName,
        phone: contactPhone,
      });
      setEditingContact(null);
      setContactName('');
      setContactPhone('');
    }
  };

  return (
    <Modal
      open={contactModalOpen}
      onClose={handleContactClose}
      aria-labelledby="contact-modal-title"
      aria-describedby="contact-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          maxWidth: '500px',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 2,
          p: 4,
          maxHeight: '90%',
          overflowY: 'auto',
        }}
      >
        <Typography id="contact-modal-title" variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
          Contact Details
        </Typography>

        <Divider sx={{ mb: 3 }} />

        <Box>
          {selectedSchool && selectedSchool.contacts.length > 0 ? (
            selectedSchool.contacts.map((contact, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mb: 2,
                  p: 2,
                  border: '1px solid #ddd',
                  borderRadius: 1,
                }}
              >
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
                    {contact.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {contact.phone}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Link href={openWhatsAppChat(contact.phone)} target="_blank" rel="noopener noreferrer">
                    <WhatsAppIcon sx={{ color: 'green', mr: 1 }} />
                  </Link>
                  <IconButton onClick={() => handleEditClick(contact)} size="small">
                    <EditIcon />
                  </IconButton>
                </Box>
              </Box>
            ))
          ) : (
            <Typography variant="body2" color="textSecondary">
              No contacts available.
            </Typography>
          )}
        </Box>

        {editingContact && (
          <Box sx={{ mt: 4 }}>
            <Divider sx={{ mb: 2 }} />
            <Typography variant="h6" sx={{ mb: 2 }}>
              Edit Contact
            </Typography>
            <TextField
              label="Name"
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
              required
            />
            <TextField
  label="Phone"
  value={contactPhone}
  onChange={(e) => {
    const value = e.target.value;
    // Ensure only numeric values
    if (/^\d+$/.test(value) || value === "") {
      setContactPhone(value);
    }
  }}
  fullWidth
  sx={{ mb: 2 }}
  type="text" // Keeps control over input type for custom validation
  error={contactPhone === ""} // Highlights if empty
  helperText={contactPhone === "" ? "Phone number is required" : ""} // Shows an error message if empty
  required // Adds a required label indicator
/>

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button variant="contained" color="primary" onClick={handleSaveClick} fullWidth sx={{ mr: 1 }}>
                Save
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setEditingContact(null)}
                fullWidth
                sx={{ ml: 1 }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default ContactModal;
