import React, { useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import {
  IconButton,
  FormControl,
  Button,
  Modal,
  Box,
  TextField,
  Collapse,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Alert,
  LinearProgress,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LinkIcon from '@mui/icons-material/Link';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/system';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AddEDUTECHVideo from './AddEDUTECHVideo'; // Import the AddNewsModal component
import CloseIcon from '@mui/icons-material/Close';

const theme = createTheme({
  palette: {
    primary: {
      main: "#8B0000",
    },
    secondary: {
      main: "#FFD700",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

const StyledButton = styled(Button)({
  marginTop: '10px',
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NewsPage = () => {
  const [news, setNews] = useState([]);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [currentNews, setCurrentNews] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [newsToDelete, setNewsToDelete] = useState(null);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [initialNews, setInitialNews] = useState(null); // New state to hold initial event data
  const [progress, setProgress] = useState(false);

  
  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async () => {
    setProgress(true);
    try {
      const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/getvideos');
      setNews(response.data.reverse());
    } catch (error) {
      setMessage("Error fetching Video: " + error.message);
      setSeverity("error");
      setAlertOpen(true);
    }
    setProgress(false);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleEditOpen = (newsItem) => {
    setCurrentNews(newsItem);
    setInitialNews(newsItem);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setCurrentNews(null);
    setInitialNews(null);
    setEditOpen(false);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const hasChanged = Object.keys(initialNews).some(
      (key) => initialNews[key] !== currentNews[key]
    );
    if (hasChanged) {
      setProgress(true);
      try {
    
        await axios.put(
          `https://edutech-backend-471f7cb69116.herokuapp.com/api/updatevideo/${currentNews._id}`,
          currentNews,
          {
            headers: {
                'Content-Type': 'application/json',
            },
          }
        );
        fetchNews();
        setMessage("Video updated successfully");
        setSeverity("success");
        setAlertOpen(true);
        handleEditClose();
      } catch (error) {
        setMessage("Error updating Video: " + error.message);
        setSeverity("error");
        setAlertOpen(true);
        handleEditClose();
      }
      setProgress(false);
    } else {
      handleEditClose();
    }
  };

  const handleOpenDeleteDialog = (id) => {
    setNewsToDelete(id);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setNewsToDelete(null);
    setDeleteDialogOpen(false);
  };

  const handleDelete = async () => {
    setProgress(true);
    try {
      await axios.delete(`https://edutech-backend-471f7cb69116.herokuapp.com/api/deletevideo/${newsToDelete}`);
      fetchNews();
      setMessage("Video deleted successfully");
      setSeverity("success");
      setAlertOpen(true);
      handleCloseDeleteDialog();
    } catch (error) {
      setMessage("Error deleting Video: " + error.message);
      setSeverity("error");
      setAlertOpen(true);
    }
    setProgress(false);
  };

  const columns = [
    { field: 'title', headerName: 'Title', width: 150 },
    {
      field: 'year',
      headerName: 'Year',
      width: 130,
    },
    {
      field: 'videoUrl',
      headerName: 'URL',
      width: 100,
      renderCell: (params) => (
        <IconButton href={params.value} target="_blank" rel="noopener noreferrer">
          <LinkIcon />
        </IconButton>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      renderCell: (params) => (
        params.row.status === '1' ? (
          <CancelIcon style={{ color: 'red' }} />
        ) : (
          <CheckCircleIcon style={{ color: 'green' }} />
        )
      ),
    },
    {
      field: 'actions',
      headerName: 'Tools',
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleEditOpen(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleOpenDeleteDialog(params.row._id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <Collapse in={alertOpen}>
          <Alert
            severity={severity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAlertOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {message}
          </Alert>
        </Collapse>
      </Box>
      <Button sx={{ mt: 1, mb: 1 }} variant="contained" color="primary" onClick={handleOpen}>
        Add Video
      </Button>
      {progress && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <div style={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={news}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          getRowId={(row) => row._id}
          disableSelectionOnClick
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              position: 'sticky',
              top: 0,
              zIndex: 1,
            },
          }}
        />
      </div>
      {currentNews && (
        <Modal
        open={editOpen}
        onClose={handleEditClose}
        aria-labelledby="edit-news-title"
        aria-describedby="edit-news-description"
        sx={{ zIndex: 1300 }} // Ensure the modal is above other content
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 600,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            overflowY: 'auto',
            maxHeight: '85%',
          }}
        >
          <Typography
            id="edit-news-title"
            variant="h5"
            component="h2"
            sx={{ mb: 3, fontWeight: 'bold', color: 'primary.main' }}
          >
            Edit Video
          </Typography>
      
          <form onSubmit={handleEditSubmit}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <TextField
                label="Title"
                value={currentNews.title}
                onChange={(e) => setCurrentNews({ ...currentNews, title: e.target.value })}
                fullWidth
                required
              />
            </FormControl>
      
      
            <FormControl fullWidth margin="normal" variant="outlined">
  <TextField
    label="Year"
    type="number"
    value={currentNews.year || ''} 
    onChange={(e) => {
      const year = e.target.value.toString();  // Convert to string
      if (year.length <= 4) {
        setCurrentNews({ ...currentNews, year: year });
      }
    }}
    fullWidth
    InputLabelProps={{ shrink: true }}
    required
    InputProps={{
      inputProps: { min: 1900, max: new Date().getFullYear(), step: 1 },
    }}
  />

</FormControl>

<FormControl fullWidth margin="normal" variant="outlined">
  <TextField
    label="URL"
    value={currentNews.videoUrl || ''}  // Use currentNews.videoUrl, not videoUrl.link
    onChange={(e) => setCurrentNews({ ...currentNews, videoUrl: e.target.value })}
    fullWidth
    required
  />
</FormControl>

      
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 3, py: 1.5, fontWeight: 'bold' }}
            >
              Save Changes
            </Button>
      
            {progress && (
              <Box sx={{ width: '100%', mt: 2 }}>
                <LinearProgress />
              </Box>
            )}
          </form>
        </Box>
      </Modal>
      
      )}
      <AddEDUTECHVideo
        open={open}
        handleClose={handleClose}
        fetchNews={fetchNews}
        setAlertOpen={setAlertOpen}
        setMessage={setMessage}
        setSeverity={setSeverity}
      />
      <Dialog
        open={deleteDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDeleteDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Delete News"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this Video ? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleCloseDeleteDialog}>
            Cancel
          </Button>
          <Button color="error" onClick={handleDelete}>Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewsPage;
