import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useEffect } from 'react';
import EdugateReg from './edugate_form';
import EdugateRegEvent from './edugate_form_event';
import FeedbackUni from './FeedbackUni';
import Stage from './Stage';
import RegPages from './RegPages';
import RegPages2 from './RegPages2';
import Drawer from './Drawer';
import Enquiry from './Enquiry';
import ProductsPage from './ProductsPage';
import NotFound from './NotFound';
import GotTalent from './GotTalent';
import EventSchedulePage from './EventSchedulePage';
import UnderConstruction from './UnderConstruction'; // Import the Under Construction page
import ErrorBoundary from './ErrorBoundary'; // Import the ErrorBoundary
import Registration_mining from './miningtech/Registration_mining';
import ComingSoon from './ComingSoon';
import Love from './love';

function App() {
  useEffect(() => {
    setTimeout(() => {
      const landscapeView = document.querySelector('.landscape-view');
      if (landscapeView) {
        landscapeView.style.transform = 'none';
        landscapeView.style.width = 'auto';
        landscapeView.style.height = 'auto';
        landscapeView.style.overflow = 'visible';
        landscapeView.style.position = 'static';
        landscapeView.style.backgroundColor = 'transparent';
        landscapeView.style.zIndex = 'auto';
        landscapeView.style.transition = 'none';
      }
    }, 1000); 
  }, []);
//      

  return (
    <Router>
      <ErrorBoundary>
        <Routes>
          <Route path="/" element={<EdugateReg />} />
          <Route path="/edugateevent" element={<EdugateRegEvent />} />
          <Route path="/FeedbackUni" element={<FeedbackUni />} />
          <Route path="/stage" element={<Stage />} />
          <Route path="/hotelabroad" element={<RegPages />} />
          <Route path="/hotelegypt" element={<RegPages2 />} />
          <Route path="/dashboard" element={<Drawer />} />
          <Route path="/Enquiry" element={<Enquiry />} />
          <Route path="/edugate-shop" element={<ProductsPage />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/GotTalent" element={<GotTalent />} />
          <Route path="/EventSchedule" element={<EventSchedulePage />} />
          <Route path="/under-construction" element={<UnderConstruction />} /> {/* Define the Under Construction route */}
          <Route path="/mining" element={<Registration_mining />} /> {/* Define the Under Construction route */}

        </Routes>
        </ErrorBoundary>
    </Router>
  );
}

export default App;
