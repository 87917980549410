import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, Modal, TextField, IconButton, Typography, Grid, InputLabel, Select, MenuItem, FormControl, LinearProgress,Dialog,
    DialogTitle,InputAdornment, Chip,DialogContentText,
    DialogContent,Slide,
    DialogActions, } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Avatar from '@mui/material/Avatar';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Collapse, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DataGrid } from '@mui/x-data-grid';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import CommentIcon from '@mui/icons-material/Comment';
import LinkIcon from '@mui/icons-material/Link';
import DownloadIcon from '@mui/icons-material/Download';
import AddIcon from '@mui/icons-material/Add';
import * as XLSX from 'xlsx';
import DeleteIcon from '@mui/icons-material/Delete';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import AddNewAgenda from './AddNewAgenda'; // Import the AddNewsModal component

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const GotTalentPage = () => {
  const [talents, setTalents] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [currentTalent, setCurrentTalent] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [initialTalent, setInitialTalent] = useState(null);
  const [progress, setProgress] = useState(false);
  const [teamModalOpen, setTeamModalOpen] = useState(false);
  const [CurrentTeam, setCurrentTeam] = useState([]);
  const [selectedComment, setSelectedComment] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [memberName, setMemberName] = useState('');
  const [TalentToDelete, setTalentToDelete] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedDay, setSelectedDay] = useState("");


  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#8B0000",
      },
      secondary: {
        main: "#000000",
      },
    },
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  });

  const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: '10px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  }));

  useEffect(() => {
    fetchTalents();
  }, []);



  const fetchTalents = async () => {
    setProgress(true);
    try {
        // Fetch the talents data
        const talentsResponse = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/agenda');
    
        setTalents(talentsResponse.data.reverse());
        const uniqueYears = Array.from(new Set(talentsResponse.data.map(item => item.year)));
        setYears(uniqueYears);
    } catch (error) {
        setMessage("Error fetching talents: " + error);
        setSeverity("error");
        setAlertOpen(true);
    }
    setProgress(false);
};


// Helper function to parse time string into Date objects
const parseTimeString = (timeString) => {
    const timeRange = timeString.split(' - ');
    const [startString, endString] = timeRange;
    
    const startDate = new Date();
    const endDate = new Date();
  
    if (startString) {
      const [startHours, startMinutes] = startString.split(':');
      startDate.setHours(parseInt(startHours, 10), parseInt(startMinutes, 10), 0, 0);
    }
  
    if (endString) {
      const [endHours, endMinutes] = endString.split(':');
      endDate.setHours(parseInt(endHours, 10), parseInt(endMinutes, 10), 0, 0);
    }
  
    return { startDate, endDate: endString ? endDate : null };
  };
  
  // Modify handleEditOpen to initialize startTime and endTime
  const handleEditOpen = (talentItem) => {
    setCurrentTalent(talentItem);
    setInitialTalent(talentItem);
  
    if (talentItem.time) {
      const { startDate, endDate } = parseTimeString(talentItem.time);
      setStartTime(startDate);
      setEndTime(endDate);
    } else {
      setStartTime(null);
      setEndTime(null);
    }
  
    setEditOpen(true);
  };
  

  const handleEditClose = () => {
    setCurrentTalent(null);
    setInitialTalent(null);
    setEditOpen(false);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
      const hasChanged = Object.keys(initialTalent).some(
      key => initialTalent[key] !== currentTalent[key]
    );
  
    if (hasChanged ) {
      setProgress(true);
      try {
        const { _id, ...updatedTalent } = currentTalent;
  
        await axios.put(`https://edutech-backend-471f7cb69116.herokuapp.com/api/updateagenda/${_id}`, updatedTalent, {
          headers: {
            'Content-Type': 'application/json',
        }
        });
        fetchTalents();
        setMessage("Section updated successfully");
        setSeverity("success");
        setAlertOpen(true);
        handleEditClose();
      } catch (error) {
        setMessage("Error updating Section: " + error);
        setSeverity("error");
        setAlertOpen(true);
        handleEditClose();
      }
      setProgress(false);
    } else {
      handleEditClose();
    }
  };
  const handleAddMember = () => {
    if (memberName.trim() !== '') {
      const updatedTeamMembers = [...currentTalent.speakers, memberName.trim()];
      
      const updatedTalent = {
        ...currentTalent,
        speakers: updatedTeamMembers
      };
      
      setCurrentTalent(updatedTalent);
      setMemberName('');
    }
  };
  
  const handleDeleteMember = (memberToDelete) => {
    const updatedTeamMembers = currentTalent.speakers.filter(member => member !== memberToDelete);
    
    const updatedTalent = {
      ...currentTalent,
      speakers: updatedTeamMembers
    };
    
    setCurrentTalent(updatedTalent);
  };
  

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddMember();
    }
  };
  const handleTeamClick = (teamMembers) => {
    setCurrentTeam(teamMembers);
    setTeamModalOpen(true);
  };

  const handleTeamModalClose = () => {
    setCurrentTeam(null);
    setTeamModalOpen(false);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedComment("");
  };
  
  const handleOpenDeleteDialog = (TalentId) => {
    setTalentToDelete(TalentId);
    setDeleteDialogOpen(true);
  };
  const handleCloseDeleteDialog = () => {
    setTalentToDelete(null);
    setDeleteDialogOpen(false);
  };

  const handleDelete = async () => {
    setProgress(true);
    try {
      await axios.delete(`https://edutech-backend-471f7cb69116.herokuapp.com/api/deleteagenda/${TalentToDelete}`);
      fetchTalents();
      setMessage("Section deleted successfully");
      setSeverity("success");
      setAlertOpen(true);
      handleCloseDeleteDialog();
    } catch (error) {
      setMessage("Error deleting Section: " + error.message);
      setSeverity("error");
      setAlertOpen(true);
    }
    setProgress(false);
  };


  const handleCommentClick = (comment) => {
    setSelectedComment(comment);
    setOpenModal(true);
  };
  const columns = [
    { field: 'year', headerName: 'Year', width: 150 },
    { field: 'day', headerName: 'Day', width: 200 },
    { field: 'time', headerName: 'Time', width: 150 },
    { field: 'session', headerName: 'Session Title', width: 100 },
    { field: 'moderator', headerName: 'Moderator', width: 150 },
    { field: 'description', headerName: 'Description', width: 150,renderCell: (params) => {
      const comments = params.value;
  
      // Check if the field is not empty
      if (comments && comments.trim() !== "") {
        return (
          <IconButton
            onClick={() => handleCommentClick(comments)} 
            variant="outlined"
          >
            <CommentIcon />
          </IconButton>
        );
      }
  
      return null;
    } },
    {
      field: 'speakers',
      headerName: 'Speakers',
      width: 150,
      renderCell: (params) => {
        const speakers = params.value;
        
        // Check if the array exists and the first item is not an empty string
        if (speakers && speakers.length !== 0) {
            return (
              <IconButton onClick={() => handleTeamClick(speakers)}>
                <Diversity3Icon />
              </IconButton>
            );
          }
          
        
        // Return null or an empty fragment if the first item is empty
        return null;
      }
    },
         {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => (
        params.value === '0' ? (
          <CancelIcon style={{ color: 'red' }} />
        ) : (
          <CheckCircleIcon style={{ color: 'green' }} />
        )
      )
    },
    {
      field: 'actions',
      headerName: 'Tools',
      width: 150,
      renderCell: (params) => (
        <Box>
          <IconButton onClick={() => handleEditOpen(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleOpenDeleteDialog(params.row._id)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      )
    }
  ];

  
  const formatTime = (startTime, endTime) => {
    if (!startTime) return ''; // Avoid formatting if no start time
  
    const formattedStart = startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const formattedEnd = endTime ? endTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : '';
    return endTime ? `${formattedStart} - ${formattedEnd}` : formattedStart;
  };
  
  const [startTime, setStartTime] = useState(null); // Start time object
  const [endTime, setEndTime] = useState(null); // End time object (optional)
  const handleStartTimeChange = (newStartTime) => {
    setStartTime(newStartTime);

    // Update the time string directly in currentTalent.time
    const formattedTime = formatTime(newStartTime, endTime);
    setCurrentTalent((prevState) => ({
      ...prevState,
      time: formattedTime,
    }));
  };
  const handleEndTimeChange = (newEndTime) => {
    setEndTime(newEndTime);

    // Update the time string directly in currentTalent.time
    const formattedTime = formatTime(startTime, newEndTime);
    setCurrentTalent((prevState) => ({
      ...prevState,
      time: formattedTime,
    }));
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleDayChange = (event) => {
    setSelectedDay(event.target.value);
  };

    // Filter carts to include only those with eventId in availableEvents
    const filteredCarts = talents.filter(cart =>
        (!selectedYear || cart.year === selectedYear) &&
        (!selectedDay ||  cart.day === selectedDay)
      );

  return (
    <ThemeProvider theme={theme}>
      <div>
      <Box sx={{ display: 'flex', gap: 3, flexWrap: 'wrap', padding: 2, backgroundColor: 'white', borderRadius: '8px', boxShadow: 1,  marginBottom: 2 }}>
      <FormControl variant="outlined" sx={{ minWidth: 240 }}>
        <InputLabel>Filter by Year</InputLabel>
        <Select
          value={selectedYear}
          onChange={handleYearChange}
          label="Filter by Year"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {years.map(year => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" sx={{ minWidth: 240, marginBottom: 2 }}>
        <InputLabel>Filter by Day</InputLabel>
        <Select
          value={selectedDay}
          onChange={handleDayChange}
          label="Filter by Day"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="Day 1">Day 1</MenuItem>
          <MenuItem value="Day 2">Day 2</MenuItem>
          <MenuItem value="Day 3">Day 3</MenuItem>

        </Select>
      </FormControl>
       
      </Box>
        <Box sx={{ width: '100%' }}>
          <Collapse in={alertOpen}>
            <Alert
              severity={severity}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlertOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {message}
            </Alert>
          </Collapse>
        </Box>
        <Button sx={{ mt: 1, mb: 1 }} variant="contained" color="primary" onClick={handleOpen}>
        Add Agenda Section
      </Button>
        {progress && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        )}
        <Box sx={{ height: 600, width: '100%' }}>
          <DataGrid
            rows={filteredCarts}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            getRowId={(row) => row._id}
            rowHeight={60}
            sx={{
              '& .MuiDataGrid-columnHeaders': {
                position: 'sticky',
                top: 0,
                zIndex: 1,
              },
            }}
          />
        </Box>
        {currentTalent && (
          <Modal
            open={editOpen}
            onClose={handleEditClose}
            aria-labelledby="edit-talent-modal"
            aria-describedby="edit-talent-modal-description"
          >
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>

            <Box
              component="form"
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 500,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
                overflowY: 'auto',
            maxHeight: '90%',
              }}
              onSubmit={handleEditSubmit}
            >

              <Typography variant="h6" component="h2" gutterBottom>
                Edit Section
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Year"
                    fullWidth
                    type="number"
                    required
                    value={currentTalent.year}
                    onChange={(e) => setCurrentTalent({ ...currentTalent, year: e.target.value.toString() })}
                    InputProps={{
                        inputProps: { min: 1900, max: new Date().getFullYear(), step: 1 },
                      }}
                  />
                </Grid>
                <Grid item xs={12}>
  <FormControl fullWidth required>
    <InputLabel>Day</InputLabel>
    <Select
      value={currentTalent.day}
      onChange={(e) => setCurrentTalent({ ...currentTalent, day: e.target.value })}
      label="Day"
    >
      <MenuItem value="Day 1">Day 1</MenuItem>
      <MenuItem value="Day 2">Day 2</MenuItem>
      <MenuItem value="Day 3">Day 3</MenuItem>
    </Select>
  </FormControl>
</Grid>

        {/* Start Time Picker */}
        <Grid item xs={12}>
          <TimePicker
            label="Start Time"
            value={startTime}
            onChange={handleStartTimeChange}
            renderInput={(params) => <TextField {...params} fullWidth required />}
            fullWidth={true}
                slotProps={{
                  textField: {
                    error: false,
                  },
                }}
                sx={{width: '100%'}}
                textFieldStyle={{width: '100%'}}      
          />
        </Grid>

        {/* End Time Picker (optional) */}
        <Grid item xs={12} >
          <TimePicker
            label="End Time (Optional)"
            value={endTime}
            onChange={handleEndTimeChange}
            renderInput={(params) => <TextField {...params} fullWidth />}
            fullWidth={true}
                slotProps={{
                  textField: {
                    error: false,
                  },
                }}
                sx={{width: '100%'}}
                textFieldStyle={{width: '100%'}}      
          />
        </Grid>

        {/* Display the formatted time (read-only) */}
        <Grid item xs={12}>
          <TextField
            label="Time"
            fullWidth
            value={currentTalent.time} // This will show the formatted time (startTime - endTime)
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Session"
                    fullWidth
                    required
                    value={currentTalent.session}
                    onChange={(e) => setCurrentTalent({ ...currentTalent, session: e.target.value })}
                  />

                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" flexDirection="column" gap={2}>
                    <TextField
                      label="Add Speaker"
                      fullWidth
                      value={memberName}
                      onChange={(e) => setMemberName(e.target.value)}
                      onKeyPress={handleKeyPress}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton aria-label="add speaker" onClick={handleAddMember}>
                              <AddIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Box display="flex" flexWrap="wrap" gap={1}>
                      {currentTalent.speakers.map((member, index) => (
                        <Chip
                          key={index}
                          label={member}
                          onDelete={() => handleDeleteMember(member)}
                          style={{ margin: '2px' }}
                        />
                      ))}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Moderator"
                    fullWidth
                    value={currentTalent.moderator}
                    onChange={(e) => setCurrentTalent({ ...currentTalent, moderator: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Description"
                    fullWidth
                    multiline
                    rows={4}
                    value={currentTalent.description}
                    onChange={(e) => setCurrentTalent({ ...currentTalent, description: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Status</InputLabel>
                    <Select
                    label="Status"
                      value={currentTalent.status}
                      onChange={(e) => setCurrentTalent({ ...currentTalent, status: e.target.value })}
                    >
                      <MenuItem value="0">Not Published</MenuItem>
                      <MenuItem value="1">Published</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
               
              </Grid>
              <StyledButton variant="contained" type="submit" fullWidth>
                Save Changes
              </StyledButton>
              {progress && (
             <Box sx={{ width: '100%', mt: 2 }}>
               <LinearProgress />
             </Box>
           )}
            </Box>
            </LocalizationProvider>

          </Modal>
        )}
        <Modal open={teamModalOpen} onClose={handleTeamModalClose}>
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            overflowY: 'auto',
            maxHeight: '90%',
          }}>
            <Typography variant="h6" component="h2" gutterBottom>
              Speakers
            </Typography>
            <ul>
              {CurrentTeam?.map((member, index) => (
                <li key={index}>{member}</li>
              ))}
            </ul>
          </Box>
        </Modal>
        <Dialog open={openModal} onClose={handleCloseModal} maxWidth="sm" fullWidth>
        <DialogContent>
          <Typography variant="body1">{selectedComment}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <AddNewAgenda
        open={open}
        handleClose={handleClose}
        fetchNews={fetchTalents}
        setAlertOpen={setAlertOpen}
        setMessage={setMessage}
        setSeverity={setSeverity}
      />
      <Dialog
        open={deleteDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDeleteDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Delete Section"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this Section? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button color="error"  onClick={handleDelete}>Delete</Button>
        </DialogActions>
      </Dialog>
      </div>
    </ThemeProvider>
  );
};

export default GotTalentPage;
