import React, { useState, useEffect } from 'react';
import {
  Modal, Box, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem, Autocomplete, LinearProgress
} from '@mui/material';

const EditModal = ({
  editModalOpen, handleEditClose, selectedSchool, handleSchoolChange,
  handleContactChange, handleEditSave, newContact, handleNewTrophyChange,
  progress, governoratesInEgypt, availableEvents, handleCityInputChange
}) => {
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    const event = getSelectedEvent();
    setSelectedEvent(event);
  }, [selectedSchool]);

  const getSelectedEvent = () => {
    if (!selectedSchool?.events || !availableEvents) return null;
  
    // Find the first matching event in availableEvents that exists in selectedSchool.events
    for (let event of selectedSchool.events) {
      const matchingEvent = availableEvents.find(availableEvent => availableEvent.id === event.event_id);
      if (matchingEvent) {
        return matchingEvent;
      }
    }
  
    return null; // Return null if no matching event is found
  };
  

  const handleEventChange = (event, value) => {
    const selectedEventId = value ? value.id : null;
    setSelectedEvent(value);

    const updatedEvents = selectedSchool.events.map(e => 
        e.event_id === selectedEventId 
            ? { ...e, which_day: selectedSchool.which_day } 
            : e
    );

    if (!updatedEvents.some(e => e.event_id === selectedEventId)) {
        updatedEvents.push({ event_id: selectedEventId, which_day: selectedSchool.which_day });
    }

    handleSchoolChange({ target: { name: 'events', value: updatedEvents } });
  };

  const handleWhichDayChange = (event) => {
    const whichDay = event.target.value;


     handleSchoolChange({ target: { name: 'which_day', value: whichDay } });
    const updatedEvents = selectedSchool.events.map(e => 
        e.event_id === selectedEvent?.id 
            ? { ...e, which_day: whichDay } 
            : e
    );

    handleSchoolChange({ target: { name: 'events', value: updatedEvents } });
  };

  return (
    <Modal
      open={editModalOpen}
      onClose={handleEditClose}
      aria-labelledby="edit-modal-title"
      aria-describedby="edit-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          maxHeight: '90%',
          overflowY: 'auto',
        }}
      >
        <Typography id="edit-modal-title" variant="h6" component="h2" gutterBottom>
          Edit School
        </Typography>

        {selectedSchool && (
          <Box>
            {/* Status Selection */}
            <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
              <InputLabel>Status</InputLabel>
              <Select
                name="status"
                value={selectedSchool.status || ''}
                onChange={handleSchoolChange}
                label="Status"
              >
                <MenuItem value="Coming">Coming</MenuItem>
                <MenuItem value="Maybe">Maybe Coming</MenuItem>
                <MenuItem value="Not Coming">Not Coming</MenuItem>
                <MenuItem value="Never Came">Never Came</MenuItem>
              </Select>
            </FormControl>

            {/* Event Selection */}
            <Autocomplete
              options={availableEvents}
              getOptionLabel={(option) => option.name || ''}
              value={selectedEvent}
              onChange={handleEventChange}
              renderInput={(params) => <TextField {...params} label="Select an Event" variant="filled" fullWidth />}
              sx={{ mb: 2 }}
            />

            {/* Which Day Selection */}
            <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
              <InputLabel>Which Day</InputLabel>
              <Select
                name="which_day"
                value={selectedSchool.which_day}
                onChange={handleWhichDayChange}
                required
                label="Which Day"
                disabled={!selectedEvent} // Disable until an event is selected
              >
                <MenuItem value="Day1">Day 1</MenuItem>
                <MenuItem value="Day2">Day 2</MenuItem>
                <MenuItem value="Day3">Day 3</MenuItem>
              </Select>
            </FormControl>

            {/* Add Note */}
            <TextField
              name="comment"
              label="Add Note"
              variant="outlined"
              fullWidth
              margin="normal"
              value={selectedSchool.comment || ''}
              onChange={handleSchoolChange}
              rows={4}
              multiline
              sx={{ mb: 2 }}
            />

            {/* School Details */}
            <TextField
              name="name"
              label="School Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={selectedSchool.name || ''}
              onChange={handleSchoolChange}
              sx={{ mb: 2 }}
              required
            />

            <TextField
              name="representative"
              label="Representative"
              variant="outlined"
              fullWidth
              margin="normal"
              value={selectedSchool.representative || ''}
              onChange={handleSchoolChange}
              sx={{ mb: 2 }}
              required
            />
             <TextField
              name="title"
              label="Title"
              variant="outlined"
              fullWidth
              margin="normal"
              value={selectedSchool.title || ''}
              onChange={handleSchoolChange}
              sx={{ mb: 2 }}
            />

            <TextField
              name="address"
              label="Address"
              variant="outlined"
              fullWidth
              margin="normal"
              value={selectedSchool.address || ''}
              onChange={handleSchoolChange}
              sx={{ mb: 2 }}
            />

            <TextField
              name="recommendedBy"
              label="Recommended By"
              variant="outlined"
              fullWidth
              margin="normal"
              value={selectedSchool.recommendedBy || ''}
              onChange={handleSchoolChange}
              sx={{ mb: 2 }}
            />

            <TextField
              name="email"
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={selectedSchool.email || ''}
              onChange={handleSchoolChange}
              sx={{ mb: 2 }}
            />

            {/* Education Type */}
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Education Type</InputLabel>
              <Select
                name="type"
                value={selectedSchool.type || ''}
                onChange={handleSchoolChange}
                label="Education Type"
              >
                <MenuItem value="American Diploma">American Diploma</MenuItem>
                <MenuItem value="IGCSE Diploma">IGCSE Diploma</MenuItem>
                <MenuItem value="Thanaweya Amma">Thanaweya Amma</MenuItem>
                <MenuItem value="Canadian Diploma">Canadian Diploma</MenuItem>
                <MenuItem value="International Baccalaureate Diploma">International Baccalaureate Diploma</MenuItem>
                <MenuItem value="French Baccalaureate Diploma">French Baccalaureate Diploma</MenuItem>
                <MenuItem value="German Abitur">German Abitur</MenuItem>
                <MenuItem value="BTEC">BTEC</MenuItem>
                <MenuItem value="WAEC">WAEC</MenuItem>
                <MenuItem value="Nile Certificarte (Cambridge)">Nile Certificarte (Cambridge)</MenuItem>
        <MenuItem value="International Education System">International Education System</MenuItem>
        <MenuItem value="Foreign Certificate">Foreign Certificate</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>

            {/* Administration */}
            <TextField
              name="administration"
              label="Administration"
              variant="outlined"
              fullWidth
              margin="normal"
              value={selectedSchool.administration || ''}
              onChange={handleSchoolChange}
              sx={{ mb: 2 }}
              required
            />

            {/* City */}
            <Autocomplete
              id="city-select-demo"
              fullWidth
              options={governoratesInEgypt}
              autoHighlight
              getOptionLabel={(option) => option ? option.label : ''} // Handle null option
              value={governoratesInEgypt.find((g) => g.label === selectedSchool.city) || null}
              onChange={(event, value) => handleCityInputChange(event, value, 'select-option')}
              onInputChange={(event, value, reason) => handleCityInputChange(event, value, reason)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose a city"
                  required
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // Disable autocomplete to avoid autofill issues
                  }}
                  name="city"
                />
              )}
              sx={{ mb: 2 }}
            />

            {/* Add New Contact */}
            <Typography variant="h6" component="h2" sx={{ mt: 2 }}>
              Add New Contact
            </Typography>

            <TextField
  name="name"
  label="Contact Name"
  variant="outlined"
  fullWidth
  margin="normal"
  value={newContact.name || ''}
  onChange={handleContactChange}
  sx={{ mb: 2 }}
/>

{newContact.name && (
  <TextField
    name="phone"
    label="Contact Phone"
    variant="outlined"
    fullWidth
    margin="normal"
    value={newContact.phone || ''}
    onChange={(e) => {
      const value = e.target.value;
      // Allow only digits and prevent empty field
      if (/^\d+$/.test(value)) {
        handleContactChange(e); // Call your handler when input is valid
      }
    }}
    error={!newContact.phone} // Show error if phone is required but empty
    helperText={!newContact.phone ? "Phone number is required" : ""}
    sx={{ mb: 2 }}
    inputProps={{ pattern: '[0-9]*' }} // Suggest numeric keypad on mobile
    type="text"
    required // Makes phone field required when visible
  />
)}



            <Button
              variant="contained"
              color="primary"
              onClick={handleEditSave}
              fullWidth
              sx={{ mt: 2 }}
            >
              Save
            </Button>
            {progress && (
              <Box sx={{ width: '100%' }}>
                <LinearProgress />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default EditModal;
