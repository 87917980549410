import React from 'react';
import {
  Modal,
  Box,
  Typography,
  Button,
  Grid,
  IconButton,
  Tooltip,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const DocumentPreviewModal = ({ open, onClose, currentParticipant }) => {
  const documents = [
    { name: 'Passport', url: currentParticipant.passport },
    { name: 'Transcript', url: currentParticipant.transcript },
    { name: 'Reference Letter', url: currentParticipant.referenceLetter },
    { name: 'Personal Statement', url: currentParticipant.personalStatement },
    { name: 'GDPR Consent', url: currentParticipant.GDPR },
    { name: 'Extra Document', url: currentParticipant.link },

  ];

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Typography variant="h6" component="h2" gutterBottom>
          Document Preview
        </Typography>
        <Grid container spacing={2}>
          {documents.map((doc, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Box
                sx={{
                  p: 2,
                  border: '1px solid #ccc',
                  borderRadius: 2,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box>
                  <Typography variant="body1">{doc.name}</Typography>
                  <Typography variant="body2" color={doc.url ? 'green' : 'red'}>
                    {doc.url ? 'Uploaded' : 'Not Uploaded'}
                  </Typography>
                </Box>
                {doc.url ? (
                  <Box>
                    <Tooltip title="Download">
                      <IconButton href={doc.url} target="_blank">
                        <DownloadIcon />
                      </IconButton>
                    </Tooltip>
                    <CheckCircleIcon color="success" />
                  </Box>
                ) : (
                  <CancelIcon color="error" />
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
        <Button variant="contained" color="primary" onClick={onClose} sx={{ mt: 3 }}>
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default DocumentPreviewModal;
