import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import {
  Box,
  Button,
  Modal,
  TextField,
  LinearProgress,
  FormControl,
  Typography,
  Alert,
  Collapse,
  Grid,List, ListItem, ListItemText, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Select, MenuItem,InputLabel,Divider
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import {createTheme, ThemeProvider } from '@mui/material/styles';
import moment from 'moment'; // For formatting the date and time
import { Autocomplete, Chip } from '@mui/material';
import countries from '../countries'; // Import your country list
import WhatshotIcon from '@mui/icons-material/Whatshot';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { IconButton, Tooltip } from '@mui/material';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import NotesIcon from '@mui/icons-material/Notes';
import { Comment, Send, Close } from '@mui/icons-material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ArchiveIcon from '@mui/icons-material/Archive';
import AddIcon from '@mui/icons-material/Add';
const TransferredParticipants = () => {
  const [selectedBefore, setSelectedBefore] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editOpen, setEditOpen] = useState(false);
  const [currentParticipant, setCurrentParticipant] = useState(null);
  const [statusFilter, setStatusFilter] = useState('');
  const [stageFilter, setStageFilter] = useState('');
  const [userFilter, setUserFilter] = useState('');
  const [photo, setPhoto] = useState(null);
  const [newComment, setNewComment] = useState('');
  const [commentOpen, setCommentOpen] = useState(false);
  const [desiredFields, setDesiredFields] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [Allcountries, setAllCountries] = useState([]);
const [archiveOpen, setArchiveOpen] = useState(false);
const [assignOpen, setAssignOpen] = useState(false);


  // Function to handle opening the modal

  const handleOpenArchive = (participant) => {
    setCurrentParticipant(participant);
    setArchiveOpen(true);
  };
  const handleCloseArchive = () => {
    setArchiveOpen(false);
    setCurrentParticipant(null);

  };
  const handleOpenAssign = (participant) => {
    setCurrentParticipant(participant);
    setAssignOpen(true);
  };
  const handleCloseAssign = () => {
    setAssignOpen(false);
    setCurrentParticipant(null);

  };

  useEffect(() => {
    // Fetch figures data from the API
    axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/figures')
      .then(response => {
        setAllCountries(response.data[5].countries);
        response="";
      })
      .catch(error => console.error('Error fetching figures data:', error));
  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#8B0000",
      },
      secondary: {
        main: "#000000",
      },
    },
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  });
  // Function to get country flag based on code
const getFlagEmoji = (countryCode) =>
  typeof String.fromCodePoint !== 'undefined'
    ? countryCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : null;

  const fetchTransferredParticipants = async () => {
    const username = sessionStorage.getItem('username'); // Get the username from session storage
    const levelnum = sessionStorage.getItem('levelnum');
    
    try {
      let response;//
        // Fetch all transferred participants if levelnum is '1'
        response = await axios.get(`https://edutech-backend-471f7cb69116.herokuapp.com/api/alltransferred-participants`);

  
      // Ensure response data exists before reversing and setting state
      if (response?.data) {
        const filteredData = response.data.filter((item) => item.archived === "0" && item.username === "");

// Optionally reverse the filtered data if needed
     setSelectedBefore(filteredData.reverse()); // Update state with filtered participants
      } else {
      }
    } catch (error) {
    } finally {
      setLoading(false); // End loading state after fetch attempt
    }
  };
  

  useEffect(() => {
    fetchTransferredParticipants();
  }, []);


  useEffect(() => {
    // Fetch figures data from the API
    axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/figures')
      .then(response => {
        setDesiredFields(response.data[3].desiredFields);
        response="";
      })
      .catch(error => console.error('Error fetching figures data:', error));
  }, []);


  const handleCommentOpen = (participant) => {
    setCurrentParticipant(participant);
    setCommentOpen(true);
  };

  const handleCommentClose = () => {
    setCurrentParticipant(null);
    setCommentOpen(false);
    setNewComment('');
  };
  const handleEditOpen = (participant) => {
    setCurrentParticipant(participant);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setCurrentParticipant(null);
    setEditOpen(false);
    setPhoto(null)
  };

  const handleEditSubmit2 = async (e) => {
    if (e) {
        e.preventDefault();  // Only call preventDefault if the event exists
    }
    try {
        const { _id,username, ...participantData } = currentParticipant; // Include username if present
        console.log("Call handleEditSubmit...");

        participantData.photo = photo; // Add the photo field to the new object
        participantData.id=_id
        await axios.put(`https://edutech-backend-471f7cb69116.herokuapp.com/api/update-participant/${_id}`,{
        username, // Ensure username is included
            ...participantData,
        },
        {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
    );

       
        setMessage('Participant updated successfully');
        setSeverity('success');
        setAlertOpen(true);
        fetchTransferredParticipants(); // Refresh the data
    } catch (error) {
        setMessage('Error updating participant: ' + error.message);
        setSeverity('error');
        setAlertOpen(true);
    }
    handleEditClose();
};
// Modify handleEditSubmit to accept the participant data as a parameter
const handleEditSubmit = async (updatedParticipant) => {
  try {
      const { _id, username, ...participantData } = updatedParticipant; // Use updatedParticipant instead of state

      participantData.photo = photo; // Add the photo field to the new object
      participantData.id=_id
      // Send updated data to the server
      await axios.put(
          `https://edutech-backend-471f7cb69116.herokuapp.com/api/update-participant/${_id}`,
          {
              username, // Ensure username is included
              ...participantData,
          },
          {
              headers: {
                  'Content-Type': 'multipart/form-data',
              },
          }
      );

      // Success message
      setMessage('Participant updated successfully');
      setSeverity('success');
      setAlertOpen(true);
      fetchTransferredParticipants(); // Refresh the data
  } catch (error) {
      setMessage('Error updating participant: ' + error.message);
      setSeverity('error');
      setAlertOpen(true);
  }
  handleEditClose();
};



  const filteredParticipants = selectedBefore.filter((participant) => {
    // Check both statusFilter and userFilter
    const statusMatches = !statusFilter || participant.status === statusFilter;
    const userMatches = !userFilter || participant.username.includes(userFilter);
    const stageMatches = !stageFilter || participant.stage.includes(stageFilter);

    return statusMatches && userMatches &&stageMatches ;
  });
  

  const columns = [
    { field: 'f_name', headerName: 'First Name', width: 150 },
    { field: 'l_name', headerName: 'Last Name', width: 150 },
   { field: 'email', headerName: 'Email', width: 200 },
    { field: 'mobile', headerName: 'Mobile', width: 150 },
    { field: 'country', headerName: 'Nationality', width: 150 },
    { field: 'birthdate', headerName: 'Birthdate', width: 150 },
    { field: 'visitor_type', headerName: 'Highest Level of Education', width: 150 },
    { field: 'school_name', headerName: 'School Name', width: 150 },
    { field: 'hear_aboutus_at', headerName: 'Hear about us at', width: 150 },
    { field: 'dateTime', headerName: 'Create Date', width: 150 },
    { field: 'username', headerName: 'Assigned Employee', width: 150 },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => {
        let icon;
        let color;
    
        switch (params.value) {
          case 'Hot':
            icon = <WhatshotIcon style={{ color: 'orange' }} />;
            color = 'green'; // Or any color you'd like for hot status
            break;
          case 'Cold':
            icon = <AcUnitIcon style={{ color: 'blue' }} />;
            color = 'blue'; // Change to your desired color for cold
            break;
          case 'Warm':
            icon = <HourglassBottomIcon style={{ color: 'gold' }} />;
            color = 'orange'; // Change to your desired color for warm
            break;
          default:
            icon = <LockOpenIcon/>; // No icon for unknown status
            color = 'gray'; // Default color
            break;
        }
        return (
          <span style={{ color }}>
            {icon} {params.value}
          </span>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 250,
      renderCell: (params) => (
        <Box>
          <Tooltip title="Open Notes" arrow>
            <IconButton onClick={() => handleCommentOpen(params.row)}>
              <NotesIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit Student Info" arrow>
            <IconButton onClick={() => handleEditOpen(params.row)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Archive Student" arrow>
          <IconButton onClick={() => handleOpenArchive(params.row)}>
              <ArchiveIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Assign Student" arrow>
          <IconButton onClick={() => handleOpenAssign(params.row)}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
    
  ];
  const handleCommentSubmit = async () => {
    if (!newComment.trim()) return;  // Ensure comment is not empty

    // Create a new comment object with timestamp
    const commentWithTimestamp = {
        text: newComment,
        dateTime: moment().format('MMMM Do YYYY, h:mm:ss a'), // Current timestamp
    };

    // Append the new comment to the participant's existing comments
    const updatedComments = [...(currentParticipant.comment || []), commentWithTimestamp];

    // Create a new object with the updated comments
    const updatedParticipantData = {
        ...currentParticipant,
        comment: updatedComments,
    };

    // Update the current participant's comments in state
    setCurrentParticipant(updatedParticipantData);
    setNewComment('');

    const { _id, ...participantData } = updatedParticipantData; // Include username if present


    try {
        // Send the entire participant's data without the _id to the backend
        await axios.put(`https://edutech-backend-471f7cb69116.herokuapp.com/api/update-participant/${_id}`, {
            ...participantData,
            photo:null
                    },{
                      headers: {
                        'Content-Type': 'multipart/form-data',
                      },
                    });

        // Optionally, you can show a success message or reload the data from the backend
        fetchTransferredParticipants(); // Refresh the data
        setMessage('Participant updated successfully');
        setSeverity('success');
        setAlertOpen(true);
      } catch (error) {
        setMessage('Error updating participant: ' + error.message);
        setSeverity('error');
        setAlertOpen(true);    }

    // Close the modal after submitting the comment
  };
  const handleDestinationsChange = (event, value) => {
    setCurrentParticipant((prev) => ({
      ...prev,
      preferredDestinations: value, // Update preferred destinations with the new selected values
    }));
  }
  const handleConfirmArchive = () => {
    // Set archived to "1"
    const newpart={
      ...currentParticipant,
      archived:"1",
      username:sessionStorage.getItem('username'),

    }
    // Call the editUpdateSubmit with updated data
    handleEditSubmit(newpart);

    // Close the dialog after action
    handleCloseArchive();  // Corrected this line to invoke the function
  };
  const handleConfirmAssign = () => {
    // Set archived to "1"
    const newpart={
      ...currentParticipant,
      username:sessionStorage.getItem('username')
    }
    // Call the editUpdateSubmit with updated data
    handleEditSubmit(newpart);

    // Close the dialog after action
    handleCloseAssign();  // Corrected this line to invoke the function
  };


  return (
<ThemeProvider theme={theme}>
      <div>     
         <Collapse in={alertOpen}>
        <Alert
          severity={severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlertOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {message}
        </Alert>
      </Collapse>
      {loading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      {loading ? (
        <p>Loading...</p>
      ) : (
        <DataGrid
        getRowId={(row) => row._id}
          rows={filteredParticipants}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10, 20, 50]}
          checkboxSelection
          autoHeight
        />
      )}

      <Modal open={editOpen} onClose={handleEditClose}
              sx={{ zIndex: 1300 }} // Ensure the modal is above other content
      >
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 600,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            overflowY: 'auto',
            maxHeight: '85%',
          }}>
          <Typography
      id="modal-title"
      variant="h5"
      component="h2"
      sx={{ mb: 3, fontWeight: 'bold', color: 'primary.main' }}
    >Edit Participant</Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>

          <form onSubmit={handleEditSubmit2}>
          <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="First Name"
              value={currentParticipant?.f_name || ''}

              onChange={(e) => setCurrentParticipant({ ...currentParticipant, f_name: e.target.value })}
              fullWidth
              required
            />
          </FormControl><FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Last Name"
              value={currentParticipant?.l_name || ''}

              onChange={(e) => setCurrentParticipant({ ...currentParticipant, l_name: e.target.value })}
              fullWidth
              required
            />
          </FormControl>

          <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Mobile"
              value={currentParticipant?.mobile || ''}

              onChange={(e) => setCurrentParticipant({ ...currentParticipant, mobile: e.target.value })}
              fullWidth
              required
            />
          </FormControl>
          <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Email"
              value={currentParticipant?.email || ''}
              onChange={(e) => setCurrentParticipant({ ...currentParticipant, email: e.target.value })}
              fullWidth
              required
              type='email'
            />
          </FormControl>
          <Autocomplete
  id="country-select-demo"
  options={Allcountries}
  getOptionLabel={(option) => option.label} // Show country name as label
  value={currentParticipant?.ResidenceCountry || null} // Bind selected country from currentParticipant
  onChange={(event, newValue) => {
    setCurrentParticipant({ ...currentParticipant, ResidenceCountry: newValue }); // Update currentParticipant's ResidenceCountry
  }} // Handle change to update currentParticipant's country
  renderOption={(props, option) => (
    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
      <img
        loading="lazy"
        width="20"
        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
        alt=""
      />
      {option.label}
    </Box>
  )}
  renderInput={(params) => (
    <TextField
      {...params}
      variant="outlined"
      label="Country of Residence"
      placeholder="Country of Residence"
      margin="normal"
    />
  )}
  isOptionEqualToValue={(option, value) => option.label === value.label} // Ensure values are matched by name
/>

          <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Budget"
              value={currentParticipant?.budget || ''}
              onChange={(e) => setCurrentParticipant({ ...currentParticipant, budget: e.target.value })}
              fullWidth
              type='number'
            />
          </FormControl>
          <FormControl variant="outlined" margin="normal" fullWidth>
              <InputLabel>Highest Education Level</InputLabel>
              <Select
                label="Highest Education Level"
                value={currentParticipant?.highestEducationLevel || ''}
                onChange={(e) => setCurrentParticipant({ ...currentParticipant, highestEducationLevel: e.target.value })}
                >
                <MenuItem value="High School">High School</MenuItem>
                      <MenuItem value="Bachelor's Degree">Bachelor's Degree</MenuItem>
                      <MenuItem value="Master's Degree">Master's Degree</MenuItem>
                      <MenuItem value="Doctorate Degree">Doctorate Degree</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" margin="normal" fullWidth>
              <InputLabel>Preferred Study Level</InputLabel>
              <Select
                label="Preferred Study Level"
                value={currentParticipant?.preferredStudyLevel || ''}
                onChange={(e) => setCurrentParticipant({ ...currentParticipant, preferredStudyLevel: e.target.value })}
                >
                <MenuItem value="School">School</MenuItem>
                      <MenuItem value="undergraduate">Undergraduate</MenuItem>
                      <MenuItem value="postgraduate">Postgraduate</MenuItem>
                      <MenuItem value="Doctorate">Doctorate</MenuItem>
                      <MenuItem value="English Language">English Language</MenuItem>
                      <MenuItem value="Vocational">Vocational</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" margin="normal" fullWidth>
              <InputLabel>English Certificate</InputLabel>
              <Select
                label="English Certificate"
                value={currentParticipant?.englishLevel || ''}
                onChange={(e) => setCurrentParticipant({ ...currentParticipant, englishLevel: e.target.value })}
                >
                      <MenuItem value="IELTS">IELTS</MenuItem>
                      <MenuItem value="TOEFL">TOEFL</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
            <Autocomplete
  multiple // Allows multiple selections
  id="desired-field-select"
  fullWidth
  options={desiredFields} // The list of desired fields
  getOptionLabel={(option) => option.label} // Display field label
  value={currentParticipant?.studyFields || []} // Bind selected fields from currentParticipant
  onChange={(event, newValue) => {
    setCurrentParticipant({
      ...currentParticipant,
      studyFields: newValue, // Update currentParticipant with the selected fields
    });
  }}
  renderOption={(props, option) => (
    <li {...props}>
      {option.label}
    </li>
  )}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Desired Fields"
      placeholder="Select desired fields"
      margin="normal"
    />
  )}
  // Display selected values as chips
  renderTags={(value, getTagProps) =>
    value.map((option, index) => (
      <Chip
        label={option.label}
        {...getTagProps({ index })}
      />
    ))
  }
  isOptionEqualToValue={(option, value) => option.label === value.label} // Match by label
/>

          <FormControl variant="outlined" margin="normal" fullWidth>
              <InputLabel>Financial Source</InputLabel>
              <Select
                label="Financial Source"
                value={currentParticipant?.financialSource || ''}
                onChange={(e) => setCurrentParticipant({ ...currentParticipant, financialSource: e.target.value })}
                >
                <MenuItem value="Self-Funded">Self-Funded</MenuItem>
                      <MenuItem value="Parents">Parents</MenuItem>
                      <MenuItem value="Scholarship">Scholarship</MenuItem>
                      <MenuItem value="Bank Loan">Bank Loan</MenuItem>
                      <MenuItem value="Employer Scholarship">Employer Scholarship</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
            <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
      <FormControl variant="outlined" margin="normal" fullWidth>
  <DatePicker
    views={['year']}
    label="Desired Year"
    // Initialize the value to the year if it exists or null
    value={currentParticipant?.intakeYear ? new Date(currentParticipant.intakeYear, 0) : null}
    onChange={(newDate) => {
      // Ensure we only save the year
      const year = newDate ? newDate.getFullYear() : null; // Extract year or set to null
      setCurrentParticipant({ ...currentParticipant, intakeYear: year });
    }}
    slotProps={{
      textField: {
        error: false,
      },
    }}
    sx={{ width: '100%' }}
    minDate={new Date(new Date().getFullYear(), 0)} // Ensure only future years are selectable
    renderInput={(params) => <TextField {...params} fullWidth />}
  />
</FormControl>
</Grid>

        {/* Month Picker */}
        <Grid item xs={12} sm={6}>
        <FormControl variant="outlined" margin="normal" fullWidth>
  <DatePicker
    views={['month']}
    label="Desired Month"
    // Initialize the value to a date object where the month is set (0-based for JS Date)
    value={currentParticipant?.intakeMonth ? new Date(2024, currentParticipant.intakeMonth - 1) : null}
    slotProps={{
      textField: {
        error: false,
      },
    }}
    onChange={(newDate) => {
      // Extract the selected month and add 1 to convert from 0-11 to 1-12
      const month = newDate ? newDate.getMonth() + 1 : null;
      setCurrentParticipant({ ...currentParticipant, intakeMonth: month });
    }}
    renderInput={(params) => <TextField {...params} fullWidth />}
  />
</FormControl>

</Grid>

          </Grid>
            <Autocomplete
              multiple
              id="country-select-demo"
              options={countries}
              getOptionLabel={(option) => option.label} // Show country name as label
              value={currentParticipant?.preferredDestinations || []} // Bind selected countries from currentParticipant
              onChange={handleDestinationsChange} // Handle change to update currentParticipant's destinations
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
               <img
                 loading="lazy"
                 width="20"
                 srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                 src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                 alt=""
               />
               {option.label} 
             </Box>                
            )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Preferred Destinations"
                  placeholder="Preferred Destinations"
                  margin="normal"
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={option.label}
                    {...getTagProps({ index })}
                    avatar={<span>{getFlagEmoji(option.code)}</span>} // Show flag in chip
                  />
                ))
              }
              isOptionEqualToValue={(option, value) => option.label === value.label} // Ensure values are matched by name
            />
            
           
            <FormControl variant="outlined" margin="normal" fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                value={currentParticipant?.status || ''}
                onChange={(e) => setCurrentParticipant({ ...currentParticipant, status: e.target.value })}
                >
               <MenuItem value="Open">Open</MenuItem>
                <MenuItem value="Cold">Cold</MenuItem>
                      <MenuItem value="Warm">Warm</MenuItem>
                      <MenuItem value="Hot">Hot</MenuItem>

              </Select>
            </FormControl>
            <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
              Save Changes
            </Button>
          </form>
          </LocalizationProvider>

        </Box>
      </Modal>
      <Modal open={commentOpen} onClose={handleCommentClose}>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      maxWidth: 600,
      bgcolor: 'background.paper',
      borderRadius: 3,
      boxShadow: 24,
      p: 4,
      overflowY: 'auto',
      maxHeight: '85%',
    }}
  >
    {/* Close Button */}
    <IconButton 
      onClick={handleCommentClose} 
      sx={{ position: 'absolute', top: 16, right: 16, color: 'grey.600' }}>
      <Close />
    </IconButton>

    {/* Title with Comment Icon */}
    <Box display="flex" alignItems="center" mb={3}>
      <Comment sx={{ color: 'primary.main', fontSize: 28, mr: 1 }} />
      <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
        Notes
      </Typography>
    </Box>

    <Divider sx={{ mb: 3 }} />

    {/* Display Existing Comments */}
    {currentParticipant?.comment?.length > 0 ? (
      currentParticipant.comment
        .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime)) // Order by date
        .map((comment, index) => (
          <Box
            key={index}
            sx={{
              mb: 2,
              p: 2,
              border: '1px solid #eee',
              borderRadius: 2,
              backgroundColor: '#f9f9f9',
              wordWrap: 'break-word',
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
              {comment.text}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
              {comment.dateTime}
            </Typography>
          </Box>
        ))
    ) : (
      <Typography variant="body1" sx={{ color: 'text.secondary', mb: 2 }}>
        No Notes yet.
      </Typography>
    )}

    <Divider sx={{ my: 3 }} />

    {/* Input Field for New Comment */}
    <TextField
      label="Add a Note"
      value={newComment}
      multiline
      rows={4}
      onChange={(e) => setNewComment(e.target.value)}
      fullWidth
      required
      margin="normal"
      variant="outlined"
      InputProps={{
        sx: { borderRadius: 2, maxWidth: '100%', width: '100%' },
        endAdornment: (
          <IconButton onClick={handleCommentSubmit} color="primary">
            <Send />
          </IconButton>
        ),
      }}
      InputLabelProps={{ sx: { fontWeight: 'bold' } }}
    />    
  </Box>
</Modal>
    </div>
 <Dialog
        open={archiveOpen}
        onClose={handleCloseArchive}
        aria-labelledby="archive-dialog-title"
        aria-describedby="archive-dialog-description"
      >
        <DialogTitle id="archive-dialog-title">
          {"Confirm Archive"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="archive-dialog-description">
          Are you sure you want to archive this student? Please note that if you proceed, the student will be assigned to you in an archived state.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseArchive} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmArchive} color="secondary" autoFocus>
            Yes, Archive
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={assignOpen}
        onClose={handleCloseAssign}
        aria-labelledby="archive-dialog-title"
        aria-describedby="archive-dialog-description"
      >
        <DialogTitle id="archive-dialog-title">
          {"Confirm Assign"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="archive-dialog-description">
            Are you sure you want to Assign this Student to yourself?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAssign} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmAssign} color="secondary" autoFocus>
            Yes, Assign
          </Button>
        </DialogActions>
      </Dialog>


    </ThemeProvider>

  );
};

export default TransferredParticipants;
