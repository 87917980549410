import React, { useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { Table, TableBody,FormControl, TableCell, InputLabel,Select,MenuItem,TableContainer, TableHead, TableRow, Paper, Button, Box, IconButton, Collapse, Modal, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide,Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PeopleIcon from '@mui/icons-material/People';
import AddEventModal from './AddEventModal';
import * as XLSX from 'xlsx';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Photo } from '@mui/icons-material';
import LinearProgress from '@mui/material/LinearProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const formatDate = (date) => dayjs(date).format('YYYY-MM-DD');


const EventsPage = () => {
  const [events, setEvents] = useState([]);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [modalOpen, setModalOpen] = useState({});
  const [total, setTotal] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const participantsPerPage = 10;
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [error, setError] = useState('');
  const [Fairphoto, setFairPhoto] = useState(null);
  const [Schedulephoto, setSchedulePhoto] = useState(null);
  const [initialEvent, setInitialEvent] = useState(null); // New state to hold initial event data
  const [progress, setProgress] = useState(false);

  

  useEffect(() => {
    fetchEvents();
  }, []);
 

  const fetchEvents = async () => {
    setProgress(true);
    try {
      const response = await axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getevent');
      setEvents((response.data).reverse());
    } catch (error) {

      setMessage("Error fetching events" + {error});
      setSeverity("error")
      setAlertOpen(true);
    }
    setProgress(false);
  };

  const fetchParticipants = async (event) => {
    try {
      const response = await axios.get(`https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getparticipants/${event.id}`);
      const reversedParticipants = response.data.reverse();
      setParticipants(reversedParticipants);
      setCurrentEvent(event);
      toggleModal(event.id);
      setTotal(response.data.length);
      setCurrentPage(0);

    } catch (error) {
      setMessage("Error fetching participants" + {error});
      setSeverity("error")
      setAlertOpen(true);
    }
  };

  const fetchParticipants2 = async (eventId) => {
    try {
      const response = await axios.get(`https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getparticipants/${eventId}`);
      const reversedParticipants = response.data.reverse();
      setParticipants(reversedParticipants);
      setTotal(response.data.length);
      setCurrentPage(0);
      return reversedParticipants;
    } catch (error) {
      setMessage("Error fetching participants" + {error});
      setSeverity("error")
      setAlertOpen(true);
      return [];
    }
  };

  const getStatus = (endDate, deleted) => {
    const today = dayjs();
    const end = dayjs(endDate);
    if (deleted === "1" || end.isBefore(today)) {
      return { text: 'Finished', isFinished: true };
    }
    return { text: `${end.diff(today, 'day')} days left`, isFinished: false };
  };

  const getStatusStyle = (status) => {
    if (status.isFinished) {
      return { color: 'green' };
    }
    return { color: 'orange' };
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleEditOpen = (event) => {
    setCurrentEvent(event);
    setInitialEvent(event);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setCurrentEvent(null);
    setInitialEvent(null);
    setEditOpen(false);
  };

  const handleDelete = async () => {
    setProgress(true);
    try {
      await axios.delete(`https://edugate-backend-b5ad4c02888d.herokuapp.com/api/deleteevent/${eventToDelete}`);
      fetchEvents();
      handleCloseDeleteDialog();
      setMessage("Event Deleted Successfully");
      setSeverity("success")
      setAlertOpen(true);
    } catch (error) {
      setMessage("Error deleting event" + {error});
      setSeverity("error")
      setAlertOpen(true);
    }
    setProgress(false);
  };

  const handleOpenDeleteDialog = (eventId) => {
    setEventToDelete(eventId);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setEventToDelete(null);
    setDeleteDialogOpen(false);
  };
  
  const handlePhotoScheduleUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validImageTypes = ['image/jpeg', 'image/png'];
      if (!validImageTypes.includes(file.type)) {
        setMessage("Please upload a valid image file (JPEG, PNG)")
        setSchedulePhoto(null);
        return;
      }
      const maxSizeBytes = 2 * 1024 * 1024; // 2MB in bytes
      if (file.size > maxSizeBytes) {
        setMessage('File size exceeds the limit of 2MB. Please choose a smaller file.');
        setSchedulePhoto(null);
        return;
      }

      // Read the file to check if it's a valid image
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          // File is valid
          const extension = file.name.split('.').pop();
          const newName = `edugate_event_${Date.now()}.${extension}`;
          const renamedFile = new File([file], newName, { type: file.type });
          setSchedulePhoto(renamedFile);
        };
        img.onerror = () => {
          setMessage('The uploaded file is not a valid image or is corrupted');
          setSchedulePhoto(null);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePhotoFairUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validImageTypes = ['image/jpeg', 'image/png'];
      if (!validImageTypes.includes(file.type)) {
        setMessage("Please upload a valid image file (JPEG, PNG)")
        setFairPhoto(null);
        return;
      }
      const maxSizeBytes = 2 * 1024 * 1024; // 2MB in bytes
      if (file.size > maxSizeBytes) {
        setMessage('File size exceeds the limit of 2MB. Please choose a smaller file.');
        setFairPhoto(null);
        return;
      }

      // Read the file to check if it's a valid image
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          // File is valid
          const extension = file.name.split('.').pop();
          const newName = `edugate_event_${Date.now()}.${extension}`;
          const renamedFile = new File([file], newName, { type: file.type });
          setFairPhoto(renamedFile);
        };
        img.onerror = () => {
          setMessage('The uploaded file is not a valid image or is corrupted');
          setFairPhoto(null);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEditSubmit = async (updatedEvent) => {

    if (dayjs(updatedEvent.end_date).isBefore(dayjs(updatedEvent.start_date))) {
      setError('End date cannot be before start date');
      return;
    }
    const formattedStartTime = dayjs(`2020-01-01 ${updatedEvent.start_time}`, 'YYYY-MM-DD HH:mm');
    const formattedEndTime = dayjs(`2020-01-01 ${updatedEvent.end_time}`, 'YYYY-MM-DD HH:mm');

    if (formattedEndTime.isBefore(formattedStartTime)) {
      setError('End time cannot be before start time');
      return;
    }
    const hasChanged = Object.keys(initialEvent).some(
      key => initialEvent[key] !== currentEvent[key]
    );
    if (hasChanged || Schedulephoto || Fairphoto ) {
      setProgress(true);

    try {
      const newUpdateevent= {
        ...updatedEvent,
        fair_schedule: Schedulephoto,
        fair_map: Fairphoto,
      }
      await axios.put(`https://edugate-backend-b5ad4c02888d.herokuapp.com/api/updateevent/${currentEvent.id}`, newUpdateevent, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      fetchEvents();
      handleEditClose();
      setMessage("Event Updated Successfully");
      setSeverity("success")
      setFairPhoto(null);
      setSchedulePhoto(null); 
      setAlertOpen(true);
    } catch (error) {
      setMessage("Error updating event" + {error});
      setSeverity("error")
      setAlertOpen(true);
    }
    setError('');
    setProgress(false);
  }
  else{
    handleEditClose();
  }
  };

  const toggleModal = (eventId) => {
    setModalOpen((prevState) => ({
      ...prevState,
      [eventId]: !prevState[eventId]
    }));
  };

  const showNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const getPaginatedParticipants = () => {
    const start = currentPage * participantsPerPage;
    return participants.slice(start, start + participantsPerPage);
  };

  const exportToExcel = async (eventId) => {
    const eventParticipants = await fetchParticipants2(eventId);
    if (eventParticipants.length === 0) {
      return;
    }
    const worksheet = XLSX.utils.json_to_sheet(eventParticipants);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Participants");
    XLSX.writeFile(workbook, `participants_${eventId}.xlsx`);
  };

  return (
    <div>
      <Button sx={{mt:1,mb:1}} variant="contained" color="primary" onClick={handleOpen}>Create New Event</Button>
      <Box sx={{ width: '100%' }}>
      <Collapse in={alertOpen}>
        <Alert
        severity={severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlertOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {message}
        </Alert>
      </Collapse>
    </Box>
        {progress && (
    <Box sx={{ width: '100%' }}>
    <LinearProgress />
                    </Box>
                )}
    <TableContainer component={Paper} sx={{ maxHeight:"100vw",maxHeight: 600 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Location Address</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Tools</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map((event) => (
              <React.Fragment key={event.id}>
                <TableRow>
                  <TableCell>{event.name}</TableCell>
                  <TableCell>{dayjs(event.start_date).format('YYYY-MM-DD')}</TableCell>
                  <TableCell>{dayjs(event.end_date).format('YYYY-MM-DD')}</TableCell>
                  <TableCell>{event.location_address}</TableCell>
                  <TableCell style={getStatusStyle(getStatus(event.end_date, event.deleted))}>
                    {getStatus(event.end_date, event.deleted).isFinished ? (
                      <>
                        {getStatus(event.end_date, event.deleted).text} <CheckCircleIcon style={{ color: 'green' }} />
                      </>
                    ) : (
                      <>
                        {getStatus(event.end_date, event.deleted).text} <HourglassBottomIcon style={{ color: 'orange' }} />
                      </>
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEditOpen(event)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteDialog(event.id)}>
                      <DeleteIcon />
                    </IconButton>
                    <IconButton onClick={() => fetchParticipants(event)}>
                      <PeopleIcon />
                    </IconButton>
                    <IconButton onClick={() => exportToExcel(event.id)}>
                      <FileDownloadIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={7}>
                    <Collapse in={modalOpen[event.id] || false} timeout="auto" unmountOnExit>
                      <Box margin={1}>
                        <h3>Participants</h3>
                        <h3>{total}</h3>
                        <Button onClick={() => toggleModal(event.id)}>Close</Button>
                        <TableContainer component={Paper}>
                          <Table size="small" aria-label="participants">
                            <TableHead>
                              <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>School Name</TableCell>
                                <TableCell>Education Type</TableCell>
                                <TableCell>Grade</TableCell>
                                <TableCell>Visitor Type</TableCell>
                                <TableCell>City</TableCell>
                                <TableCell>Register Date</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {currentEvent && currentEvent.id === event.id && getPaginatedParticipants().map((participant) => (
                                <TableRow key={participant.id}>
                                  <TableCell>{participant.f_name} {" "} {participant.l_name}</TableCell>
                                  <TableCell>{participant.email}</TableCell>
                                  <TableCell>{participant.school_name}</TableCell>
                                  <TableCell>{participant.education_type}</TableCell>
                                  <TableCell>{participant.grade}</TableCell>
                                  <TableCell>{participant.visitor_type}</TableCell>
                                  <TableCell>{participant.city}</TableCell>
                                  <TableCell>{participant.date}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        {currentPage * participantsPerPage + participantsPerPage < total && (
                          <Button onClick={showNext}>Show Next</Button>
                        )}
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AddEventModal open={open} handleClose={handleClose} fetchEvents={fetchEvents} setAlertOpen={setAlertOpen} setMessage={setMessage} setSeverity={setSeverity} />
      {currentEvent && (
        <Modal open={editOpen} onClose={handleEditClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxHeight: '90%',
            overflowY: 'auto',
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            width: 500,
          }}
        >
          <Typography
            variant="h5"
            component="h2"
            sx={{ mb: 3, color: 'primary.main', fontWeight: 'bold', textAlign: 'center' }}
          >
            Edit Event
          </Typography>
      
          <form onSubmit={(e) => { e.preventDefault(); handleEditSubmit(currentEvent); }}>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Name"
                value={currentEvent.name}
                onChange={(e) => setCurrentEvent({ ...currentEvent, name: e.target.value })}
                fullWidth
                variant="outlined"
                required
              />
            </FormControl>
      
            <Box sx={{ display: 'flex', gap: 2 }}>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="Start Date"
                  type="date"
                  value={dayjs(currentEvent.start_date).format('YYYY-MM-DD')}
                  onChange={(e) => setCurrentEvent({ ...currentEvent, start_date: e.target.value })}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="End Date"
                  type="date"
                  value={dayjs(currentEvent.end_date).format('YYYY-MM-DD')}
                  onChange={(e) => setCurrentEvent({ ...currentEvent, end_date: e.target.value })}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </FormControl>
            </Box>
      
            <Box sx={{ display: 'flex', gap: 2 }}>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="Start Time"
                  type="time"
                  value={currentEvent.start_time}
                  onChange={(e) => setCurrentEvent({ ...currentEvent, start_time: e.target.value })}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="End Time"
                  type="time"
                  value={currentEvent.end_time}
                  onChange={(e) => setCurrentEvent({ ...currentEvent, end_time: e.target.value })}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </FormControl>
            </Box>
      
            <FormControl fullWidth margin="normal">
              <TextField
                label="Location Address"
                value={currentEvent.location_address}
                onChange={(e) => setCurrentEvent({ ...currentEvent, location_address: e.target.value })}
                fullWidth
                variant="outlined"
                required
              />
            </FormControl>
      
            <FormControl fullWidth margin="normal">
              <TextField
                label="Google Embedded Address"
                value={currentEvent.embedded_map}
                onChange={(e) => setCurrentEvent({ ...currentEvent, embedded_map: e.target.value })}
                fullWidth
                variant="outlined"
                required
              />
            </FormControl>
      
            <FormControl fullWidth margin="normal">
              <TextField
                label="Location URL"
                value={currentEvent.map}
                onChange={(e) => setCurrentEvent({ ...currentEvent, map: e.target.value })}
                fullWidth
                variant="outlined"
                required
              />
            </FormControl>
      
            <FormControl fullWidth margin="normal">
              <TextField
                label="Fair Map URL"
                value={currentEvent.fair_map}
                fullWidth
                variant="outlined"
                disabled
              />
            </FormControl>
      
            <FormControl fullWidth margin="normal">
              <TextField
                label="Fair Schedule URL"
                value={currentEvent.fair_schedule}
                fullWidth
                variant="outlined"

                disabled
              />
            </FormControl>
            <Box sx={{  mt:2,display: 'flex', gap: 2 }}>
            <FormControl required fullWidth>
                      <InputLabel>Stage</InputLabel>
                      <Select
                        label="Stage"
                        value={currentEvent.has_stage}
                        name="Stage"
                        fullWidth
                        required
                        onChange={(e) => setCurrentEvent({ ...currentEvent, has_stage: e.target.value })}

                      >  
                        <MenuItem value="1">Create Stage</MenuItem>
                        <MenuItem value="0">No Stage</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl required fullWidth>
                      <InputLabel>Got Talent</InputLabel>
                      <Select
                        label="Got Talent"
                        value={currentEvent.has_talent}
                        name="Got Talent"
                        fullWidth
                        onChange={(e) => setCurrentEvent({ ...currentEvent, has_talent: e.target.value })}
                        required
                      >  
                        <MenuItem value="1">Create Got Talent</MenuItem>
                        <MenuItem value="0">No Got Talent</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl required fullWidth>
                      <InputLabel>School Event</InputLabel>
                      <Select
                        label="School Event"
                        value={currentEvent.is_school}
                        name="is_school"
                        fullWidth
                        required
                        onChange={(e) => setCurrentEvent({ ...currentEvent, is_school: e.target.value })}

                      >  
                        <MenuItem value="1">Yes</MenuItem>
                        <MenuItem value="0">No</MenuItem>
                      </Select>
                    </FormControl>
                    </Box>
      
            <Box sx={{ mb: 2 }}>
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="upload-photo_fair"
          type="file"
          onChange={handlePhotoFairUpload}
        />
        <label htmlFor="upload-photo_fair">
          <Button
            startIcon={<CloudUploadIcon />}
            variant="outlined"
            component="span"
            fullWidth
            sx={{
              mt: 2,
              py: 1.5,
              fontWeight: 'bold',
              borderColor: 'primary.main',
            }}
          >
            Upload Fair Map
          </Button>
        </label>
        {Fairphoto && <Typography sx={{ mt: 1 }}>{Fairphoto.name}</Typography>}
      </Box>

      <Box sx={{ mb: 2 }}>
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="upload-photo_schedule"
          type="file"
          onChange={handlePhotoScheduleUpload}
        />
        <label htmlFor="upload-photo_schedule">
          <Button
            startIcon={<CloudUploadIcon />}
            variant="outlined"
            component="span"
            fullWidth
            sx={{
              mt: 2,
              py: 1.5,
              fontWeight: 'bold',
              borderColor: 'primary.main',
            }}
          >
            Upload Fair Schedule
          </Button>
        </label>
        {Schedulephoto && <Typography sx={{ mt: 1 }}>{Schedulephoto.name}</Typography>}
      </Box>
      
            {error && (
              <Typography color="error" variant="body2" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
      
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 3, py: 1.5, fontWeight: 'bold' }}
            >
              Submit
            </Button>
      
            {progress && (
              <Box sx={{ width: '100%', mt: 2 }}>
                <LinearProgress />
              </Box>
            )}
          </form>
        </Box>
      </Modal>
      
      )}
      <Dialog
        open={deleteDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDeleteDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Delete Event"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this event? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button  color="secondary" onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button color="error" onClick={handleDelete} >Delete</Button>
        </DialogActions>
      </Dialog>

      
    </div>
  );
};

export default EventsPage;//
