import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { styled } from '@mui/system';

// Custom styles
const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundImage: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
    borderRadius: '16px',
  },
}));

const SelectEvent = ({ formData, handleChange, setEvent }) => {
  const [open, setOpen] = useState(true);
  const [availableEvents, setAvailableEvents] = useState([]);
  const [error, setError] = useState('');

  const fetchAvailableEvents = async () => {
    try {
      const response = await axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getevent');
      const filteredEvents = response.data.filter((event) => event.deleted === '0' && event.is_school === '0');
      setAvailableEvents(filteredEvents);
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchAvailableEvents();
    setOpen(true);
  }, []);

  const handleClose = () => {
    if (formData.eventID) {
      const selectedEvent = availableEvents.find(event => event.id === formData.eventID);
      setEvent(selectedEvent);
      setOpen(false);
    } else {
      setError('Please select an event before proceeding');
    }
  };

  return (
    <React.Fragment>
      <CustomDialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        aria-labelledby="download-dialog-title"
        aria-describedby="download-dialog-description"
      >
        <DialogTitle id="download-dialog-title" sx={{ textAlign: 'center', paddingBottom: 0 }}>
          <Typography variant="h4" component="div" sx={{ fontWeight: 'bold', color: '#333' }}>
            EDU GATE
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FormControl sx={{ mt: 2 }} fullWidth required>
            <InputLabel>Which Event are you Registering for?</InputLabel>
            <Select
              name="eventID"
              value={formData.eventID}
              onChange={handleChange}
              required
              label="Which Event are you Registering for?"
            >
              {availableEvents.map((event) => (
                <MenuItem key={event.id} value={event.id}>
                  {event.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {error && (
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}
          <Box mt={2}>
            <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'center' }}>
              Please note that the dates are not final and may change.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', paddingBottom: '16px' }}>
          <Button onClick={handleClose} sx={{ color: '#555' }}>
            Select Event
          </Button>
        </DialogActions>
      </CustomDialog>
    </React.Fragment>
  );
};

export default SelectEvent;
