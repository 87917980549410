import React, { useEffect, useState } from 'react';
import {
  Container,
  TextField,
  CssBaseline,
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  Box,
  LinearProgress,
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import axios from 'axios';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { de, enGB, zhCN } from 'date-fns/locale';
import { format } from 'date-fns';

const theme = createTheme({
  palette: {
    primary: {
      main: '#8B0000',
    },
    secondary: {
      main: '#000000',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

const StyledCard = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '100%',
  maxWidth: '600px',
  margin: 'auto',
  padding: '20px',
  borderRadius: '10px',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  background: '#ffffff',
});

const StyledButton = styled(Button)({
  marginTop: '10px',
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
});

const NotificationForm = () => {
  const [info, setInfo] = useState({
    students: "0",
    events: "0",
    exhibitors: "0",
    international_organizations: "0",
    ministries: "0",
    date: null,
    name: '',
    startDate: null,});

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedback, setFeedback] = useState({ message: '', type: '' });
  const [progress, setProgress] = useState(false);

  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/figures');
      const firstRecord = response.data[0];

      setInfo(firstRecord);
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setProgress(true);
  
    // Extract _id from info to avoid MongoDB errors
    const { _id, ...updatedInfo } = info; 
  
  
    try {
      const response = await axios.put(`https://edutech-backend-471f7cb69116.herokuapp.com/api/updateinfo/${_id}`, updatedInfo);
      
      if (response.status === 200) {
        setFeedback({ message: 'Info Updated successfully!', type: 'success' });
      } else {
        setFeedback({ message: 'Error updating', type: 'error' });
      }
    } catch (error) {
      setFeedback({ message: 'Network error. Please try again later.', type: 'error' });
    } finally {
      setIsSubmitting(false);
      setProgress(false);
    }
  };
  

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="sm">
        <StyledCard>
          <Typography variant="h4" color="primary" gutterBottom>
            General Info
          </Typography>
          <CardContent>
          <LocalizationProvider dateAdapter={AdapterDateFns}  adapterLocale={enGB}>

            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    label="Students"
                    fullWidth
                    type='number'
                    value={info.students}
                    onChange={(e) => setInfo({ ...info,
                        students:e.target.value.toString()})}
                    required
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Events"
                    fullWidth
                    type='number'
                    value={info.events}
                    onChange={(e) => setInfo({ ...info,
                        events:e.target.value.toString()})}
                    required
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Exhibitors"
                    fullWidth
                    type='number'
                    value={info.exhibitors}
                    onChange={(e) => setInfo({ ...info,
                        exhibitors:e.target.value.toString()})}         
                                   required
                                   autoFocus
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="International Organizations"
                    fullWidth
                    type='number'
                    value={info.international_organizations}
                    onChange={(e) => setInfo({ ...info,
                        international_organizations:e.target.value.toString()})}
                    required
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Ministries"
                    fullWidth
                    type='number'
                    value={info.ministries}
                    onChange={(e) => setInfo({ ...info,
                   ministries:e.target.value.toString()})}     
                 required
                 autoFocus
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Event Description"
                    fullWidth
                    multiline
                    autoFocus
                    rows={4}
                    value={info.name}
                    onChange={(e) => setInfo({ ...info,
                        name:e.target.value})}        
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                <DesktopDatePicker
  required
  label="Start Date"
  inputFormat="yyyy/MM/dd" // This is just for display in the picker, formatting will happen on change.
  value={info.startDate ? new Date(info.startDate) : null} // Parse the date to JS Date object if already present in info.
  onChange={(newDate) => {
    // Format the date to 'yyyy-MM-dd' and then update state
    const formattedDate = newDate ? format(newDate, 'yyyy-MM-dd') : '';
    setInfo({ ...info, startDate: formattedDate,date:formattedDate });
  }}
  renderInput={(params) => <TextField {...params} fullWidth required />}
  fullWidth={true}
  openTo="year"
  views={['year', 'month', 'day']}
  minDate={new Date()}
  slotProps={{
    textField: {
      error: false,
    },
  }}
  sx={{width: '100%'}}
  textFieldStyle={{width: '100%'}}
/>
          </Grid>
                
                <Grid item xs={12}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    fullWidth
                  >
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                  </StyledButton>
                </Grid>
                {progress && (
                  <Box sx={{ width: '100%', mt: 1 }}>
                    <LinearProgress />
                  </Box>
                )}
                {feedback.message && (
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      style={{ color: feedback.type === 'error' ? 'red' : 'green' }}
                      align="center"
                    >
                      {feedback.message}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </form>
            </LocalizationProvider>
          </CardContent>
        </StyledCard>
      </Container>
    </ThemeProvider>
  );
};

export default NotificationForm;
