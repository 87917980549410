import React from 'react';
import { TextField, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const SearchComponent = ({ searchQuery, handleSearchChange }) => {
  return (
    <TextField
      value={searchQuery}
      onChange={(e) => handleSearchChange(e.target.value)} // Capture search input
      placeholder="Search by Phone or School Name..."
      InputProps={{
        endAdornment: (
          <IconButton>
            <SearchIcon />
          </IconButton>
        ),
      }}
      sx={{ backgroundColor: 'white', borderRadius: 1, margin: 1 }}
      fullWidth
    />
  );
};

export default SearchComponent;
