import React, { useEffect, useState } from 'react';
import {
  Container,
  TextField,
  CssBaseline,
  Button,
  Grid,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Card,
  CardContent,
  Chip,
  Box,
  LinearProgress,
  Autocomplete,
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import axios from 'axios';

const theme = createTheme({
  palette: {
    primary: {
      main: '#8B0000',
    },
    secondary: {
      main: '#000000',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

const StyledCard = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '100%',
  maxWidth: '600px',
  margin: 'auto',
  padding: '20px',
  borderRadius: '10px',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  background: '#ffffff',
});

const StyledButton = styled(Button)({
  marginTop: '10px',
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
});

const NotificationForm = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [fullDescription, setFullDescription] = useState('');
  const [image, setImage] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [notificationType, setNotificationType] = useState('');
  const [status, setStatus] = useState('not done');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedback, setFeedback] = useState({ message: '', type: '' });
  const [progress, setProgress] = useState(false);

  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/users');
      setUsers(response.data);
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    // Automatically select image based on notification type
    if (notificationType === 'announcement') {
      setImage('https://cdn-icons-png.flaticon.com/512/5875/5875271.png');
    } else if (notificationType === 'update') {
      setImage('https://t3.ftcdn.net/jpg/02/93/37/28/360_F_293372811_2gpgNsJ3TQSPsfAbmBPIYfuKfAw70bpt.jpg');
    } else if (notificationType === 'task') {
      setImage('https://cdn-icons-png.flaticon.com/512/2098/2098402.png');
    }
  }, [notificationType]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setProgress(true);

    if (!title || !description || !fullDescription || !notificationType) {
      setFeedback({ message: 'Please fill in all the required fields', type: 'error' });
      setIsSubmitting(false);
      setProgress(false);
      return;
    }

    const notification = {
      title,
      description,
      fullDescription,
      image,
      users: selectedUsers,
      notificationType,
      status,
    };

    try {
      const response = await axios.post('https://edutech-backend-471f7cb69116.herokuapp.com/api/notifications', notification);
      if (response.status === 201) {
        setFeedback({ message: 'Notification created successfully!', type: 'success' });
        // Clear form after successful submission
        setTitle('');
        setDescription('');
        setFullDescription('');
        setSelectedUsers([]);
        setNotificationType('');
        setStatus('not done');
      } else {
        setFeedback({ message: 'Error creating notification', type: 'error' });
      }
    } catch (error) {
      setFeedback({ message: 'Network error. Please try again later.', type: 'error' });
    } finally {
      setIsSubmitting(false);
      setProgress(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="sm">
        <StyledCard>
          <Typography variant="h4" color="primary" gutterBottom>
            Create Notification
          </Typography>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    label="Title"
                    fullWidth
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Description"
                    fullWidth
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Full Description"
                    fullWidth
                    multiline
                    rows={4}
                    value={fullDescription}
                    onChange={(e) => setFullDescription(e.target.value)}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth required>
                    <InputLabel>Notification Type</InputLabel>
                    <Select
                      label="Notification Type"
                      value={notificationType}
                      onChange={(e) => setNotificationType(e.target.value)}
                    >
                      <MenuItem value="announcement">Announcement</MenuItem>
                      <MenuItem value="update">Update</MenuItem>
                      <MenuItem value="task">Task</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    options={users}
                    getOptionLabel={(option) => option.name}
                    value={selectedUsers}
                    onChange={(event, newValue) => {
                      setSelectedUsers(newValue);
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          label={option.name}
                          {...getTagProps({ index })}
                          key={option._id}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Assign to Users"
                        placeholder="Select users"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    fullWidth
                  >
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                  </StyledButton>
                </Grid>
                {progress && (
                  <Box sx={{ width: '100%', mt: 1 }}>
                    <LinearProgress />
                  </Box>
                )}
                {feedback.message && (
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      style={{ color: feedback.type === 'error' ? 'red' : 'green' }}
                      align="center"
                    >
                      {feedback.message}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </form>
          </CardContent>
        </StyledCard>
      </Container>
    </ThemeProvider>
  );
};

export default NotificationForm;
