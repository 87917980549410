import React, { useState,useEffect } from 'react';
import {CardContent,CssBaseline,InputLabel,FormControl,TextField, Typography, Box, Button, Grid, MenuItem, LinearProgress } from '@mui/material';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ReportProblem from '../ReportProblem';
import { styled } from '@mui/system';
import Card from '@mui/material/Card';
import "../RegistrationForm.css"
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import Select from '@mui/material/Select';
import CheckIcon from '@mui/icons-material/Check';
import Alert from '@mui/material/Alert';

const theme = createTheme({
    palette: {
      primary: {
        main: "#d47c4c"
      }
    }
  });
  const StyledTitle = styled(Typography)(({ theme }) => ({
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'bold',
    fontSize: 'clamp(1.2rem, 4vw, 2.5rem)', // Responsive font size
    color: 'transparent',
    background: 'linear-gradient(135deg, #d47c4c 0%, #f4a261 50%, #e76f51 100%)', // Gradient with similar shades
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
    position: 'relative',
    display: 'inline-block',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '2px',
      backgroundColor: '#d47c4c', // Accent color for underline
      transform: 'scaleX(0)',
      transformOrigin: 'bottom right',
      transition: 'transform 0.3s ease-out',
    },
    '&:hover::after': {
      transform: 'scaleX(1)',
      transformOrigin: 'bottom left',
    },
  }));
  const BackgroundContainer = styled('div')({
    minHeight: '100vh',
    width: '100vw', // Ensure full width
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '20px',
    background: 'linear-gradient(135deg, #d47c4c 0%, #f4a261 50%, #e76f51 100%)', // Gradient with similar shades
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    overflow: 'hidden',
    position: 'relative',
    zIndex: 1,
    '::before': {
      content: '""',
      position: 'absolute',
      top: '-50px',
      left: '-50px',
      width: '300px',
      height: '300px',
      background: 'rgba(255, 255, 255, 0.1)', // Subtle white blur
      borderRadius: '50%',
      zIndex: -1,
    },
    '::after': {
      content: '""',
      position: 'absolute',
      bottom: '-100px',
      right: '-100px',
      width: '200px',
      height: '200px',
      background: 'rgba(255, 255, 255, 0.1)', // Subtle white blur on the opposite side
      borderRadius: '50%',
      zIndex: -1,
    },
  });
  
  const StyledCard = styled(Card)({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '100%',
    maxWidth: '800px',
    margin: 'auto',
    background: 'rgba(255, 255, 255, 0.9)', // Slightly transparent white background
    padding: '20px',
    borderRadius: '20px', // Smooth rounded corners
    boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.2)', // Soft and professional shadow
    position:"relative"
  });
  
  // Adjusted Button Style
  const StyledButton = styled(Button)({
    marginTop: '10px',
    padding: '12px 20px',
    background: 'linear-gradient(135deg, #d47c4c 0%, #f4a261 50%, #e76f51 100%)', // Gradient with similar shades
    color: '#fff',
    '&:hover': {
      background: 'linear-gradient(135deg, #d47c4c 0%, #f4a261 50%, #e76f51 100%)', // Gradient with similar shades
    },
    transition: 'background-color 0.3s',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    borderRadius: '30px',
  });
  
  const RegistrationForm = () => {
    const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    country: "",
    email: '',
    referralSource: '',
    title: '',
    company: '',
    fieldOfInterest: '',
    event_id:'1'
  });
  const [feedback, setFeedback] = useState({ message: '', type: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [progress, setProgress] = useState(false);
  const [countries, setCountries] = useState([]);


  useEffect(() => {
    // Fetch figures data from the API
    axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/figures')
      .then(response => {
        setCountries(response.data[5].countries);
      })
      .catch(error => console.error('Error fetching figures data:', error));
  }, []);

  const convertArabicToEnglish = (input) => {
    const arabicNumbers = '٠١٢٣٤٥٦٧٨٩';
    const englishNumbers = '0123456789';
    let output = '';
    for (let i = 0; i < input.length; i++) {
      const index = arabicNumbers.indexOf(input[i]);
      output += index !== -1 ? englishNumbers[index] : input[i];
    }
    return output;
  };


  const [formStates, setFormStates] = useState(null);
  useEffect(() => {
    fetchFormStates();
  }, []);
  
  
  const fetchFormStates = async () => {
    try {
      const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/formstates');
      setFormStates(response.data.Mining_TECH.Registration);
    } catch (error) {
      console.error('Error fetching form states', error);
    }
  };
  
  const handleChange = (event) => {
    const { name, value } = event.target;
    const convertedValue = convertArabicToEnglish(value);
    setFormData({ ...formData, [name]: convertedValue });
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!formData.email || !formData.firstName || !formData.lastName || !formData.mobile) {
      setFeedback({
        message: "Please fill in all the required fields",
        type: 'error',
      });
      return;
    }

    setIsSubmitting(true);
    setProgress(true);

    try {
      // const formattedDate = dayjs(formData.birth_date).format('DD/MM/YYYY');

      const response = await fetch('https://agritech-backend-768fe146b7a5.herokuapp.com/api/register-mining', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 201) {
        const responseData = await response.json();
        const pdfPath = responseData.pdfUrl;

        setFeedback({
          message: "Thank you for registering",
          type: 'success',
        });

        const link = document.createElement('a');
        link.href = pdfPath;
        link.download = 'invitation.pdf';
        link.click();
      } else if (response.status === 400) {
        setFeedback({
          message: "You have already registered with this email before",
          type: 'error',
        });
      } else {
        const errorData = await response.json();
        setFeedback({
          message: "The server is busy handling other requests. Please refresh the page and try again after 5 minutes.",
          type: 'error',
        });
      }
    } catch (error) {
      setFeedback({
        message: "Network error: " + error.message,
        type: 'error',
      });
    }

    setProgress(false);
    setIsSubmitting(false);
  };
  
  

  return (

    <div>

    {formStates && (
            <div style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 9999,
              width: '400px'
            }}>
              <Alert 
                icon={<CheckIcon fontSize="inherit" />} 
                severity="error"
                style={{ display: 'flex', alignItems: 'center' }}
              >
       Registration is now closed as the Event has been postponed. A new date will be announced soon. We appreciate your understanding.
    </Alert>
            </div>
          )}
    
    <div style={formStates ? { filter: 'blur(5px)', pointerEvents: 'none' } : {}}>
    

    <BackgroundContainer>
          <div style={{marginTop:"50px",marginBottom:"50px", width:"90vw"}} className="form-menu-container">
          <CssBaseline />
          <StyledCard>
          <CardContent>



      
         <Box sx={{ position: 'absolute', right: 16, top: 16 }}> {/* Positioning the ReportProblem component */}
    <Tooltip title="Report a Problem">
    <IconButton
  color="inherit"
  aria-label="notifications"
  sx={{
    mr: 2,
    zIndex: 1200, // Ensures the menu appears on top of other elements
  }}
>
<ReportProblem dynamicNumber="Mining Form" />
          </IconButton>
        </Tooltip>
        </Box>
        
        <Box display="flex" justifyContent="center" mb={2}>
          <img src="./mining-logo-low.png" alt="Mining-Tech Logo" style={{ width: '200px' }} />
        </Box>
        <Box sx={{ textAlign: 'center', mt: 4 }}>

       <StyledTitle variant="h5" gutterBottom>

Registration         </StyledTitle>
        </Box>

        <ThemeProvider theme={theme}>
        <form onSubmit={handleSubmit}>


          <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
  <TextField
    required
    fullWidth
    label="First Name"
    name="firstName"
    value={formData.firstName}
   // onChange={handleChange}
    onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}

  />
</Grid>
<Grid item xs={12} sm={6}>
  <TextField
    required
    fullWidth
    label="Last Name"
    name="lastName"
    value={formData.lastName}
    //onChange={handleChange}
    onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
  />
</Grid>
<Grid item xs={12} sm={6}>
  <TextField
    required
    fullWidth
    label="Mobile Number"
    name="mobile"
    value={formData.mobile}
    //onChange={handleChange}
    onChange={(e) => setFormData({ ...formData, mobile: e.target.value })}

  />
</Grid>
<Grid item xs={12} sm={6}>
<Autocomplete
  id="country-select-demo"
  fullWidth
  options={countries}
  autoHighlight
  getOptionLabel={(option) => option.label}
  value={countries.find((c) => c.label === formData.country) || null}
  onChange={(event, newValue) => {
    setFormData({
      ...formData,
      country: newValue ? newValue.label : '', // Only update state when selection changes
    });
  }}
  renderOption={(props, option) => (
    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
      <img
        loading="lazy"
        width="20"
        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
        alt=""
      />
      {option.label}
    </Box>
  )}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Choose a country"
      name="country"
      required
    />
  )}
/>

</Grid>
<Grid item xs={12} sm={6}>
  <TextField
    required
    fullWidth
    label="Email"
    name="email"  // Add this line
    type="email"
    value={formData.email}
   // onChange={handleChange}
    onChange={(e) => setFormData({ ...formData, email: e.target.value })}

  />
</Grid>
<Grid item xs={12} sm={6}>
  <TextField
    fullWidth
    label="Title"
    value={formData.title}
   // onChange={handleChange}
    id='title'
    onChange={(e) => setFormData({ ...formData, title: e.target.value })}

  />
</Grid>
<Grid item xs={12} sm={6}>
  <TextField
    fullWidth
    label="Company"
    name="company"
    value={formData.company}
    //onChange={handleChange}
    onChange={(e) => setFormData({ ...formData, company: e.target.value })}

  />
</Grid>
<Grid item xs={12} sm={6}>
  <TextField
    fullWidth
    label="Which Field Are You Interested In?"
    name="fieldOfInterest"
    value={formData.fieldOfInterest}
    onChange={(e) => setFormData({ ...formData, fieldOfInterest: e.target.value })}

  //  onChange={handleChange}
  />
</Grid>
<Grid item xs={12}>
<FormControl fullWidth required>
  <InputLabel id="referral-source-label">How Did You Hear About Us?</InputLabel>
  <Select
    labelId="referral-source-label"
    id="referralSource"
    name="referralSource"
    value={formData.referralSource}
    //onChange={handleChange}
    onChange={(e) => setFormData({ ...formData, referralSource: e.target.value })}

    required
    label="How Did You Hear About Us?"
  >
    <MenuItem value="Website">Website</MenuItem>
    <MenuItem value="Friend">Friend</MenuItem>
    <MenuItem value="Google">Google Search</MenuItem>
    <MenuItem value="Facebook">Facebook</MenuItem>
    <MenuItem value="Instagram">Instagram</MenuItem>
    <MenuItem value="Linkedin">LinkedIn</MenuItem>
    <MenuItem value="Tiktok">Tiktok</MenuItem>
    <MenuItem value="WhatsApp">WhatsApp</MenuItem>
    <MenuItem value="Email">Email</MenuItem>
    <MenuItem value="SMS">SMS</MenuItem>
    <MenuItem value="Radio">Radio</MenuItem>
    <MenuItem value="Outdoor">Outdoor</MenuItem>
  </Select>
</FormControl>

</Grid>

          </Grid>
          <Box mt={4} display="flex" justifyContent="center">
            <StyledButton 
              type="submit" 
              variant="contained" 
              size="large" 
              sx={{ width: '50%' }}
              disabled={isSubmitting || formStates}
            //disabled="true"
            >
              Register
            </StyledButton>
          </Box>

          </form>

          {progress && <LinearProgress sx={{mt:2,mb: 2 }} />}
          </ThemeProvider>

          {feedback.message && (
            <Typography 
              variant="body1" 
              color={feedback.type === 'success' ? 'green' : 'red'}
              align="center"
              sx={{ mt: 2 }}
            >
              {feedback.message}
            </Typography>

          )}
        </CardContent>

        </StyledCard>

        </div>
    </BackgroundContainer>
    </div>
    </div>


  );
}
export default RegistrationForm;

