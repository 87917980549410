import React, { useState, useRef, useEffect } from 'react';
import { styled, keyframes } from '@mui/system'; // Import keyframes for animation
import { useTransition, animated } from 'react-spring';
import { FaHeart } from 'react-icons/fa';
import Confetti from 'react-confetti';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import IconButton from '@mui/material/IconButton'; // Import IconButton from MUI

// Styled components using @mui/system
// Keyframes for floating hearts animation
const float = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0); }
`;
const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  background: 'linear-gradient(135deg, #ff758f 0%, #ffacb7 100%)',
  fontFamily: 'Arial, sans-serif',
  textAlign: 'center',
  padding: '0 20px',
  overflow: 'hidden',
  position: 'relative',
});

const Button = styled('button')({
  padding: '12px 24px',
  fontSize: '20px',
  color: 'white',
  backgroundColor: '#ff6f61',
  border: 'none',
  borderRadius: '12px',
  marginTop: '20px',
  cursor: 'pointer',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: '#ff9671',
  },
});

const Input = styled('input')({
  padding: '10px',
  fontSize: '18px',
  border: '2px solid #ff6f61',
  borderRadius: '10px',
  outline: 'none',
  marginTop: '20px',
  textAlign: 'center',
});

const LoveText = styled(animated.h1)({
  fontSize: '2.5rem',
  color: '#fff',
  fontWeight: 'bold',
  textShadow: '2px 2px 8px rgba(255, 255, 255, 0.5)',
});




const ErrorMessage = styled('p')({
  color: 'red',
  marginTop: '10px',
});

const FinalMessage = styled(animated.h1)({
    fontSize: '4.5rem', // Slightly larger for more emphasis
    color: '#ff1493', // A vibrant pink for a romantic feel
    fontFamily: "'Great Vibes', cursive", // Keep a cursive font for elegance
    textShadow: '3px 3px 10px rgba(255, 255, 255, 0.6)', // Enhance text shadow for depth
    marginTop: '40px', // More margin for spacing
    textAlign: 'center', // Center the text
    lineHeight: '1.2', // Increase line height for readability
    background: 'rgba(255, 255, 255, 0.8)', // Subtle white background for contrast
    padding: '20px', // Padding to create space around text
    borderRadius: '15px', // Rounded corners
    boxShadow: '0 8px 25px rgba(0, 0, 0, 0.3)', // Pronounced shadow for depth
  });
  
  const Image = styled('img')({
    marginTop: '30px', // Increased margin for better spacing
    borderRadius: '20px', // Softer corners
    boxShadow: '0 15px 30px rgba(0, 0, 0, 0.5)', // More pronounced shadow for depth
    maxWidth: '90%', // Allow image to take up more space for better impact
    height: 'auto',
    border: '8px solid rgba(255, 105, 180, 0.8)', // A soft pink border for a romantic theme
    transition: 'transform 0.3s, box-shadow 0.3s', // Smooth transition effect
    '&:hover': {
      transform: 'scale(1.05)', // Slightly enlarge on hover for an interactive feel
      boxShadow: '0 20px 40px rgba(255, 105, 180, 0.5)', // Enhanced shadow on hover
    },
  });
  
  
const FloatingHearts = styled(FaHeart)(({ top, left, size }) => ({
  position: 'absolute',
  top: `${top}%`,
  left: `${left}%`,
  color: 'rgba(255, 0, 0, 0.8)',
  fontSize: `${size}rem`,
  animation: `${float} ${Math.random() * 4 + 3}s ease-in infinite`,
}));

const AudioPlayer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginTop: '20px',
  background: '#fff',
  borderRadius: '50px',
  padding: '10px',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
});

const Progress = styled('input')({
    margin: '10px',
    cursor: 'pointer',
    width: '100%',
    '-webkit-appearance': 'none',
    appearance: 'none',
    height: '8px',
    background: 'pink', // Track color
    borderRadius: '5px',
  
    // Style for the thumb in WebKit browsers
    '&::-webkit-slider-thumb': {
      '-webkit-appearance': 'none',
      appearance: 'none',
      width: '24px',
      height: '24px',
      background: 'transparent', // Ensure the thumb is transparent
      border: 'none', // Remove any border
      boxShadow: 'none', // Remove any shadow
      position: 'relative',
    },
  
    // Style for the thumb in Firefox
    '&::-moz-range-thumb': {
      width: '24px',
      height: '24px',
      background: 'transparent', // Ensure the thumb is transparent
      border: 'none', // Remove any border
      boxShadow: 'none', // Remove any shadow
      position: 'relative',
    },
  
    // Style for the thumb in Internet Explorer
    '&::-ms-thumb': {
      width: '24px',
      height: '24px',
      background: 'transparent', // Ensure the thumb is transparent
      border: 'none', // Remove any border
      boxShadow: 'none', // Remove any shadow
      position: 'relative',
    },
  
    // Style for the track
    '&::-webkit-slider-runnable-track': {
      height: '8px',
      borderRadius: '5px', // Optional: rounded edges for the track
      background: 'pink', // Ensure track color
    },
  
    '&::-moz-range-track': {
      height: '8px',
      borderRadius: '5px',
      background: 'pink', // Ensure track color
    },
  
    '&::-ms-track': {
      height: '8px',
      background: 'pink', // Ensure track color
      borderRadius: '5px',
      color: 'transparent', // Hide the color for IE
      border: 'none', // Remove border for IE
    },
  });
  
// Heart Icon styled as a thumb
const HeartIcon = styled(FaHeart)({
  position: 'absolute',
  top: '-8px', // Adjust to center the heart
  left: '50%', // Center the heart
  transform: 'translateX(-50%)', // Center the heart
  color: 'red', // Heart color
  fontSize: '24px', // Heart size
  pointerEvents: 'none', // Make sure it doesn't capture mouse events
});

const messages = [
  "Hi Lolo, Please Play the Music...",
  "just thinking of you makes my heart skip a beat.",
  "You hold my heart in a way no one else ever could.",
  "Your smile lights up my life and makes everything better.",
  "Hearing your laughter is the sweetest sound to me.",
  "Every moment we share feels like a beautiful dream.",
  "There's something very important I need to tell you...",
  "Lolo, my love for you knows no limits.",
  "I love you more than I can ever say. You mean the world to me. ❤️"
];

function App() {
  const [index, setIndex] = useState(-1); // -1 to show password input first
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [confetti, setConfetti] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(null);

  // Handle the next button click to show the next message
  const handleNextClick = () => {
    if (index < messages.length - 1) {
      setIndex(index + 1);
    }
    else{
        setIndex(messages.length);

    }
    console.log(index)

  };

  // Handle password check
  const handlePasswordSubmit = () => {
    if (password === 'la ana lolo') {
      setIndex(0); // Start showing the messages
      setError(false);
    } else {
      setError(true); // Show error if password is incorrect
    }
  };

  // Handle audio play/pause
  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  // Update the current time of the audio
  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
    setDuration(audioRef.current.duration);
  };

  // Update progress bar when song is playing
  const handleSeek = (e) => {
    const seekTime = e.target.value;
    audioRef.current.currentTime = seekTime;
    setCurrentTime(seekTime);
  };

  const transitions = useTransition(index, {
    key: index,
    from: { opacity: 0, transform: 'translateY(-20px)' },
    enter: { opacity: 1, transform: 'translateY(0)' },
    config: { duration: 1000 },
    onRest: () => {
      if (index === messages.length - 1) {
        setConfetti(true);  // Trigger confetti after final message
      }
    },
  });

  const renderHearts = () => {
    return Array(20).fill().map((_, i) => (
      <FloatingHearts
        key={i}
        top={Math.random() * 100}
        left={Math.random() * 100}
        size={Math.random() * 6 + 5}  // Bigger hearts: size between 3 and 8
      />
    ));
  };
  


  // Use effect to listen to audio events
  useEffect(() => {
    const audio = audioRef.current;
    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('loadedmetadata', () => {
      setDuration(audio.duration); // Update duration when audio metadata is loaded
    });
    return () => {
      audio.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, []);

  return (
    <Container>

      {/* Confetti trigger on final message */}
      {confetti && <Confetti />}

      {/* Render floating hearts */}
      {renderHearts()}

      {index === -1 ? (
        // Password Input Screen
        <>
          <LoveText>Please enter the password to proceed:</LoveText>
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
          />
          <Button onClick={handlePasswordSubmit}>Submit</Button>
          {error && <ErrorMessage>You're not Lolo 😢😞, try again!</ErrorMessage>}
        </>
      ) :(
        // Show the messages with transitions
        <>
          {transitions((style, i) => (
            <LoveText key={i} style={style}>{messages[i]}</LoveText>
          ))}
          {index < messages.length && (

          <Button onClick={handleNextClick}>Next</Button>)}
        </>

      )
      }
{(index === messages.length) && (
  // Final "I love you" message with SVG and sound
  <>
    <FinalMessage style={{ opacity: 1, transform: 'translateY(0)', transition: 'opacity 1s, transform 1s' }}>
      I love you, Lolo! ❤️
    </FinalMessage>
    
    <Image src="./IMG_8025.png" alt="Us together" /> 
  </>
)}


      {/* Audio Player */}
      {/* Audio Player */}
{index >= 0  && index != messages.length && (
  <AudioPlayer>
    <IconButton onClick={handlePlayPause}>
      {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
    </IconButton>
    <div style={{ position: 'relative', width: '100%' }}>
      <Progress
        type="range"
        value={currentTime}
        min="0"
        max={duration}
        onChange={handleSeek}
      />
      <HeartIcon
        style={{
          left: duration ? `${(currentTime / duration) * 100}%` : '0%',////
        }}
      />
    </div>
  </AudioPlayer>
)}

      <audio ref={audioRef} loop>
        <source src="./audio.mp3" type="audio/mp3" />
      </audio> 
    </Container>
  );
}

export default App;
