import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Switch,
  FormControlLabel,
  Collapse,
  Alert,
  LinearProgress,
  Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import { DataGrid } from '@mui/x-data-grid';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const RegistrationFormPage = () => {
  const [registrations, setRegistrations] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [currentRegistration, setCurrentRegistration] = useState(null);
  const [initialRegistration, setInitialRegistration] = useState(null);
  const [availableEvents, setAvailableEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [progress, setProgress] = useState(false);

  useEffect(() => {
    fetchRegistrations();
    fetchAvailableEvents();
  }, []);//

  const fetchRegistrations = async () => {
    setProgress(true);
    try {
      const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/getenquireedugate');
      setRegistrations(response.data.reverse());
    } catch (error) {
      setMessage("Error fetching enquires: " + error.message);
      setSeverity("error");
      setAlertOpen(true);
    }
    setProgress(false);
  };

  const fetchAvailableEvents = async () => {
    try {
      const response = await axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getevent');
      setAvailableEvents(response.data.reverse());
    } catch (error) {
      setMessage("Error fetching events: " + error.message);
      setSeverity("error");
      setAlertOpen(true);
    }
  };

  const handleEditOpen = (registration) => {
    setCurrentRegistration(registration);
    setInitialRegistration(registration);
    setEditOpen(true);
  };

  

  const handleEditClose = () => {
    setCurrentRegistration(null);
    setInitialRegistration(null);
    setEditOpen(false);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    if (currentRegistration.status !== initialRegistration.status) {
      setProgress(true);
      try {
        await axios.put(`https://edutech-backend-471f7cb69116.herokuapp.com/api/enquire_edugate/${currentRegistration._id}`, currentRegistration);
        fetchRegistrations();
        setMessage("Status updated successfully");
        setSeverity("success");
        setAlertOpen(true);
        handleEditClose();
      } catch (error) {
        setMessage("Error updating status: " + error.message);
        setSeverity("error");
        setAlertOpen(true);
      }
      setProgress(false);
    } else {
      handleEditClose();
    }
  };

  const handleSwitchChange = (field) => (event) => {
    setCurrentRegistration({ ...currentRegistration, [field]: event.target.checked ? "1" : "0" });
  };

  const handleEventChange = (event) => {
    setSelectedEvent(event.target.value);
  };

  const filteredRegistrations = registrations.filter(registration => 
    !selectedEvent || registration.whichEvent === selectedEvent
  );

  const columns = [{
    field: 'eventName',
    headerName: 'Event Name',
    width: 150,
    renderCell: (params) => {
      const event = availableEvents.find(event => event.id === params.row.whichEvent);
      return event ? event.name : 'Unknown Event';
    }
  },
    { field: 'Corporate_Name', headerName: 'Corporate Name', width: 150 },
    { field: 'Contact_person', headerName: 'Contact Person', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'mobile', headerName: 'Mobile', width: 150 },
    { field: 'Country', headerName: 'Country', width: 120 },
    { field: 'city', headerName: 'City', width: 120 },
    { field: 'Organization_website_or_Facebook_page', headerName: 'Website/Facebook', width: 180 },
    { field: 'Kind_of_Participation', headerName: 'Participation', width: 150 },
    { field: 'date', headerName: 'Date', width: 150 },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      renderCell: (params) => (
        params.value === '1' ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />
      )
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      renderCell: (params) => (
        <IconButton onClick={() => handleEditOpen(params.row)}>
          <EditIcon />
        </IconButton>
      )
    }
  ];
  const exportToExcel = () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileName = 'edugate_enquiry';
    const ws = XLSX.utils.json_to_sheet(filteredRegistrations);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <Collapse in={alertOpen}>
          <Alert
            severity={severity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setAlertOpen(false)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {message}
          </Alert>
        </Collapse>
      </Box>
      <Box sx={{ height: '100%', width: '100%' }}>
      <Box sx={{ padding: 3, backgroundColor: 'white', borderRadius: 2, boxShadow: 3, mb: 3 }}>
      {sessionStorage.getItem('levelnum') === '1' && (

        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Button onClick={exportToExcel} variant="contained" color="success" sx={{ fontSize: '16px', py: 1.5 }}
          disabled={sessionStorage.getItem('levelnum') !== "1"}
          >
            Export Data to Excel
          </Button>
         
        </Box>)}
      <FormControl variant="filled" sx={{ m: 1, minWidth: 240 }}>
        <InputLabel id="event-select-label">Select Event</InputLabel>
        <Select
          labelId="event-select-label"
          id="event-select"
          value={selectedEvent}
          onChange={handleEventChange}
          sx={{ backgroundColor: 'white' }}
        >
          {availableEvents.map(event => (
            <MenuItem key={event.id} value={event.id}>{event.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      </Box>
      </Box>
      {progress && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <Box sx={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={filteredRegistrations}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
          getRowId={(row) => row._id}
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              position: 'sticky',
              top: 0,
              zIndex: 1,
            },
          }}
          components={{
            NoRowsOverlay: () => <div>No records found</div>
          }}
        />
      </Box>
      {currentRegistration && (
       <Modal
       open={editOpen}
       onClose={handleEditClose}
       aria-labelledby="edit-registration-title"
       aria-describedby="edit-registration-description"
       sx={{ zIndex: 1300 }} // Ensure the modal is above other content
     >
       <Box
         sx={{
           position: 'absolute',
           top: '50%',
           left: '50%',
           transform: 'translate(-50%, -50%)',
           width: '90%',
           maxWidth: 400,
           bgcolor: 'background.paper',
           borderRadius: 2,
           boxShadow: 24,
           p: 4,
           overflowY: 'auto',
           maxHeight: '85%',
         }}
       >
         <Typography
           id="edit-registration-title"
           variant="h5"
           component="h2"
           sx={{ mb: 3, fontWeight: 'bold', color: 'primary.main' }}
         >
           Edit Registration
         </Typography>
     
         <form onSubmit={handleEditSubmit}>
           <FormControl fullWidth margin="normal">
             <FormControlLabel
               control={
                 <Switch
                   checked={currentRegistration.status === '1'}
                   onChange={handleSwitchChange('status')}
                 />
               }
               label="Activation"
             />
           </FormControl>
     
           <Button
             type="submit"
             variant="contained"
             color="primary"
             fullWidth
             sx={{ mt: 2, py: 1.5, fontWeight: 'bold' }}
           >
             Save Changes
           </Button>
     
           {progress && (
             <Box sx={{ width: '100%', mt: 2 }}>
               <LinearProgress />
             </Box>
           )}
         </form>
       </Box>
     </Modal>
     
      )}
    </div>
  );
};

export default RegistrationFormPage;
