import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

export default function SimplifiedDataGrid() {
  const [participants, setParticipants] = useState([]);
  const [progress, setProgress] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState('');

  useEffect(() => {
    const fetchParticipants = async () => {
      setProgress(true);
      try {
        const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/getallparticipanttech');
        const data = response.data;
        if (data.length > 0) {
          const mappedData = data.map(row => ({
            id: row._id,
            firstName: row.firstName,
            lastName: row.lastName,
            email: row.email,
            mobile: row.mobile,
            schoolName: row.schoolName,
            eduLevel: row.eduLevel,
            studyField: row.studyField,
            howDidYouKnow: row.howDidYouKnow,
            eventID: getEventName(row.eventID),
          }));
          setParticipants(mappedData.reverse());
        }
      } catch (error) {
      }
      setProgress(false);
    };

    fetchParticipants();
  }, []);

  const getEventName = (eventId) => {
    const eventNames = {
      '1': 'First Edition',
      '2': 'Second Edition',
      '3': 'Third Edition',
      '4': 'Fourth Edition',
      '5': 'Fifth Edition',
    };
    return eventNames[eventId] || 'Unknown Edition';
  };

  const handleEventChange = (event) => {
    setSelectedEvent(event.target.value);
  };

  const filteredParticipants = participants.filter(participant =>
    selectedEvent ? participant.eventID === getEventName(selectedEvent) : true
  );

  const exportToExcel = () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileName = 'participants_data';
    const ws = XLSX.utils.json_to_sheet(filteredParticipants);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <Box sx={{ padding: 3, backgroundColor: 'white', borderRadius: 2, boxShadow: 3, mb: 3 }}>
        {/* Flexbox container for the filter and export button */}
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 4 }}>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="event-select-label">Filter by Event</InputLabel>
            <Select
              labelId="event-select-label"
              value={selectedEvent}
              label="Filter by Event"
              onChange={handleEventChange}
            >
              <MenuItem value=""><em>All Events</em></MenuItem>
              <MenuItem value="1">First Edition</MenuItem>
              <MenuItem value="2">Second Edition</MenuItem>
              <MenuItem value="3">Third Edition</MenuItem>
              <MenuItem value="4">Fourth Edition</MenuItem>
              <MenuItem value="5">Fifth Edition</MenuItem>
            </Select>
          </FormControl>
  
          <Button onClick={exportToExcel} variant="contained" color="success" sx={{ fontSize: '16px', py: 1.5,marginLeft:1 }}>
            Export Data to Excel
          </Button>
        </Box>
      </Box>
  
      {progress && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
  
      <DataGrid 
        autoHeight
        rows={filteredParticipants}
        pageSize={20}
        columns={[
          { field: 'id', headerName: 'ID', width: 100 },
          { field: 'firstName', headerName: 'First Name', width: 150 },
          { field: 'lastName', headerName: 'Last Name', width: 150 },
          { field: 'email', headerName: 'Email', width: 200 },
          { field: 'mobile', headerName: 'Mobile', width: 150 },
          { field: 'schoolName', headerName: 'School Name', width: 150 },
          { field: 'eduLevel', headerName: 'edu Level', width: 200 },
          { field: 'studyField', headerName: 'study Field', width: 150 },
          { field: 'howDidYouKnow', headerName: 'how Did You Know', width: 200 },
          { field: 'eventID', headerName: 'Event', width: 150 }
        ]}
        slots={{ toolbar: GridToolbar }}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
  );
}  