import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SpeakerCardEdutech from './SpeakerCardEdutech';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { FormControl, InputLabel, MenuItem, Grid,Select, Button, Typography, Container, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, IconButton, Collapse } from '@mui/material';
import * as XLSX from 'xlsx';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import LinearProgress from '@mui/material/LinearProgress';

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000"
    },
    secondary: {
      main: "#FF0000"
    },
    background: {
      default: "#f4f4f9"
    }
  },
  typography: {
    h4: {
      marginBottom: '20px'
    },
    button: {
      textTransform: 'none'
    }
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: '20px',
          borderRadius: '8px',
          backgroundColor: '#fff',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
        }
      }
    }
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StageCheck = () => {
  const [speakers, setSpeakers] = useState([]);
  const [slots, setSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedEvent, setSelectedEvent] = useState(2);
  const [selectedStatus, setSelectedStatus] = useState(''); // State for selected status
  const [events, setEvents] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [speakerToDelete, setSpeakerToDelete] = useState('');   
  const [StatusToDelete, setStatusToDelete] = useState(''); 
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [progress, setProgress] = useState(false);

  useEffect(() => {
    fetchSpeakers();
 }, []); // Added dependency array
 
  useEffect(() => {
    if (selectedEvent) {
      fetchSlots(selectedEvent);
    }
  }, [selectedEvent]);


  const fetchSpeakers = async () => {
    setProgress(true);
    try {
      const response = await axios.get('https://agritech-backend-768fe146b7a5.herokuapp.com/api/stage_data');
      const filteredSpeakers = response.data.filter(speaker => speaker.event_id ===selectedEvent);
      setSpeakers(filteredSpeakers);
    } catch (error) {
      setMessage("Error fetching speakers: " + error.message);
      setSeverity("error");
      setAlertOpen(true);
    }
    setProgress(false);

  };

  const fetchSlots = async (eventId) => {
    try {
        const response = await axios.get(`https://agritech-backend-768fe146b7a5.herokuapp.com/api/slots?event=${eventId}`);
        setSlots(response.data);
      // Extract unique dates for the selected event
      const dates = Array.from(new Set(response.data.map(slot => slot.date)));
      setAvailableDates(dates);
    } catch (error) {
      setMessage("Error fetching slots: " + error.message);
      setSeverity("error");
      setAlertOpen(true);
    }
  };

 const updateSpeaker = async (id, updatedData) => {
    try {
      const response = await axios.put(
        `https://agritech-backend-768fe146b7a5.herokuapp.com/api/speakers/${id}`,
        updatedData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      

        // Fetch updated data
        if (response.status === 205) {
          setMessage("This slot is no longer available, Please choose a new slot");
          setSeverity("error");
          setAlertOpen(true);
          return;
                }
        const updatedSpeaker = response ? response.data : null;
        
        // Optionally fetch speakers and slots if needed
        fetchSpeakers();
        fetchSlots(selectedEvent);
        
        // Display success message
        setMessage("Speaker updated Successfully");
        setSeverity("success");
        setAlertOpen(true);

        // Return the updated speaker data
        return updatedSpeaker;

    } catch (error) {
        // Handle errors and display error message
        setMessage("Error updating speaker: " + error.message);
        setSeverity("error");
        setAlertOpen(true);
        throw error; // Re-throw the error if needed
    }
};


  const handleOpenDeleteDialog = (id,status) => {
    setSpeakerToDelete(id);
    setStatusToDelete(status);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setSpeakerToDelete('');
    setStatusToDelete("");

    setDeleteDialogOpen(false);
  };

  const deleteSpeaker = async () => {
    try {
      // Delete speaker from the first API
      await axios.delete(`https://agritech-backend-768fe146b7a5.herokuapp.com/api/speakers/${speakerToDelete}`);
      // Refresh the data
      fetchSpeakers();
      fetchSlots(selectedEvent);
  
      // Set success message and open alert
      setMessage("Speaker deleted successfully");
      setSeverity("success");
      setAlertOpen(true);
  
      // Close the delete dialog
      handleCloseDeleteDialog();
    } catch (error) {
      // Set error message and open alert
      setMessage("Error deleting speaker: " + error.message);
      setSeverity("error");
      setAlertOpen(true);
    }
  };
  



  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const parseTime = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };

  const filteredSpeakers = speakers.filter(speaker => {
    const matchesEvent = selectedEvent ? speaker.event_id === selectedEvent : true;
    const matchesDate = selectedDate ? speaker.date === selectedDate : true;
    const matchesStatus = selectedStatus ? speaker.status === selectedStatus : true;
    return matchesEvent && matchesDate && matchesStatus;
  }).sort((a, b) => {
    if (a.date < b.date) return -1;
    if (a.date > b.date) return 1;
    const [aStartTime] = a.slot.split(' - ');
    const [bStartTime] = b.slot.split(' - ');
    return parseTime(aStartTime) - parseTime(bStartTime);
  });

  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredSpeakers);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Speakers");
    XLSX.writeFile(workbook, "speakers_data.xlsx");
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md">
        {progress && (
    <Box sx={{ width: '100%' }}>
    <LinearProgress />
                    </Box>
                )}
          <Typography variant="h4" color="primary">
            Stage Booked Slots
          </Typography>
          <Box sx={{ padding: 2, backgroundColor: '#fff', borderRadius: 2, boxShadow: 3, mb: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={exportToExcel}
            fullWidth
            sx={{ py: 1.5, fontSize: '16px' }}
          >
            Export to Excel
          </Button>
        </Grid>


        <Grid item xs={12} md={3}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="date-select-label">Select Date</InputLabel>
            <Select
              labelId="date-select-label"
              id="date-select"
              value={selectedDate}
              onChange={handleDateChange}
              sx={{ backgroundColor: 'white' }}
              disabled={!selectedEvent}
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {availableDates.map(date => (
                <MenuItem key={date} value={date}>
                  {date}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="status-select-label">Select Status</InputLabel>
            <Select
              labelId="status-select-label"
              id="status-select"
              value={selectedStatus}
              onChange={handleStatusChange}
              sx={{ backgroundColor: 'white' }}
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              <MenuItem value="1">Submitted</MenuItem>
              <MenuItem value="0">Not Submitted</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
        <Box sx={{ width: '100%' }}>
          <Collapse in={alertOpen}>
            <Alert
              severity={severity}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlertOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {message}
            </Alert>
          </Collapse>
        </Box>
        <Box>
          {filteredSpeakers.map(speaker => (
            <SpeakerCardEdutech
              key={speaker._id}
              speaker={speaker}
              updateSpeaker={updateSpeaker}
              deleteSpeaker={() => handleOpenDeleteDialog(speaker._id,speaker.status)}
              allSlots={slots}
            />
          ))}
        </Box>
      </Container>
      <Dialog
        open={deleteDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Delete Speaker"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this Speaker? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleCloseDeleteDialog}>
            Cancel
          </Button>
          <Button color="error" onClick={deleteSpeaker}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default StageCheck;
