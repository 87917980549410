import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  AppBar,
  Toolbar,
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  TextField,
  Button,
  IconButton,
  Badge,
  Container,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Pagination
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import EditIcon from '@mui/icons-material/Edit';
import Cart from './Cart'; // Import Cart component
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Autocomplete from '@mui/material/Autocomplete';

const theme = createTheme({
  palette: {
    primary: {
      main: "#8B0000"
    }
  }
});

const ProductsPage = () => {
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [category, setCategory] = useState('');
  const [sortOption, setSortOption] = useState('');
  const [cart, setCart] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isViewMoreOpen, setIsViewMoreOpen] = useState(false);
  const [isCustomizationModalOpen, setIsCustomizationModalOpen] = useState(false);
  const [customizationTexts, setCustomizationTexts] = useState({});
  const [customizationOptions, setCustomizationOptions] = useState({});
  const [page, setPage] = useState(1);
  const [error, setError] = useState('');
  const itemsPerPage = 8;

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/getproducts');
      const filteredProducts = response.data.filter(product => product.deleted !== "1");
      setProducts(filteredProducts);
    } catch (error) {
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  const handleAddToCart = (product) => {
    const existingProductIndex = cart.findIndex(item => item._id === product._id);
    const isTextSelected = customizationOptions[product._id]?.text || false;
    const customizationText = customizationTexts[product._id] || '';

    if (isTextSelected && customizationText.trim() === '') {
      setError('Please fill in the customization text.');

      return;
    }

    const updatedProduct = {
      ...product,
      quantity: existingProductIndex !== -1 ? cart[existingProductIndex].quantity : 1,
      customizationText,
      customizationOptions: { ...customizationOptions[product._id] }
    };

    if (existingProductIndex !== -1) {
      const updatedCart = [...cart];
      updatedCart[existingProductIndex] = updatedProduct;
      setCart(updatedCart);
    } else {
      setCart([...cart, updatedProduct]);
    }
  };

  

  const handleQuantityChange = (product, delta) => {
    const existingProductIndex = cart.findIndex(item => item._id === product._id);
    if (existingProductIndex !== -1) {
      const updatedCart = [...cart];
      const updatedQuantity = cart[existingProductIndex].quantity + delta;
      if (updatedQuantity <= 0) {
        // If quantity becomes 0 or less, remove the product from the cart
        updatedCart.splice(existingProductIndex, 1);
        // Reset customization options and text for the removed product
        setCustomizationOptions(prevOptions => {
          const updatedOptions = { ...prevOptions };
          delete updatedOptions[product._id];
          return updatedOptions;
        });
        setCustomizationTexts(prevTexts => {
          const updatedTexts = { ...prevTexts };
          delete updatedTexts[product._id];
          return updatedTexts;
        });
      } else {
        updatedCart[existingProductIndex] = { ...cart[existingProductIndex], quantity: updatedQuantity };
      }
      setCart(updatedCart);
    } else if (delta > 0) {
      setCart([...cart, { ...product, quantity: 1 }]);
    }
  };

  const handleQuantityInputChange = (product, value) => {
    const existingProductIndex = cart.findIndex(item => item._id === product._id);
    if (existingProductIndex !== -1) {
      const updatedCart = [...cart];
      const updatedQuantity = parseInt(value, 10);
      if (isNaN(updatedQuantity) || updatedQuantity <= 0) {
        updatedCart.splice(existingProductIndex, 1);
      } else {
        updatedCart[existingProductIndex] = { ...cart[existingProductIndex], quantity: updatedQuantity };
      }
      setCart(updatedCart);
    }
  };

  const handleCustomizationTextChange = (productId, value) => {
    setCustomizationTexts({ ...customizationTexts, [productId]: value });

    // Update customization text in the cart
    setCart(cart.map(item => item._id === productId ? { ...item, customizationText: value } : item));
  };

  const handleCartOpen = () => {
    setIsCartOpen(true);
  };

  const handleCartClose = () => {
    setIsCartOpen(false);
  };

  const handleViewMoreOpen = (product) => {
    setSelectedProduct(product);
    setIsViewMoreOpen(true);
  };

  const handleViewMoreClose = () => {
    setIsViewMoreOpen(false);
    setSelectedProduct(null);
  };

  const handleCustomizationModalOpen = (product) => {
    setSelectedProduct(product);
    setIsCustomizationModalOpen(true);
  };

  const handleCustomizationModalClose = () => {
    setIsCustomizationModalOpen(false);
    setSelectedProduct(null);
  };

  const handleCustomizationOptionsChange = (productId, option) => {
    setCustomizationOptions(prevOptions => {
      const updatedOptions = { ...prevOptions };
      updatedOptions[productId] = updatedOptions[productId] || {};
      updatedOptions[productId][option] = !updatedOptions[productId][option];
      return updatedOptions;
    });
  };

  const isTextSelected = (productId) => {
    return customizationOptions[productId]?.text || false;
  };

  const getCategories = () => {
    const categories = products.map(product => product.category);
    return [...new Set(categories)];
  };

  const filteredProducts = products
    .filter(product =>
      product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      product.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
      product.category.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .filter(product =>
      category === '' || product.category.toLowerCase() === category.toLowerCase()
    )
    .sort((a, b) => {
      if (sortOption === 'nameAsc') return a.name.localeCompare(b.name);
      if (sortOption === 'nameDesc') return b.name.localeCompare(a.name);
      if (sortOption === 'priceAsc') return a.priceD - b.priceD;
      if (sortOption === 'priceDesc') return b.priceD - a.priceD;
      return 0;
    });

  const totalItemsInCart = cart.reduce((total, item) => total + item.quantity, 0);

  const paginatedProducts = filteredProducts.slice((page - 1) * itemsPerPage, page * itemsPerPage);
  const productOptions = products.map(product => ({ title: product.name }));

  return (
    <Box sx={{ backgroundColor: '#fafafa', minHeight: '100vh', pt: 4 }}>
      <ThemeProvider theme={theme}>
        <AppBar position="static" sx={{ mb: 4, backgroundColor: 'darkred' }}>
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img src="https://edugate-eg.com/wp-content/uploads/2023/04/10-YEARS-EDUGATE-copy-1.png" alt="Logo" style={{ height: '60px' }} />
              <Typography variant="h6" sx={{ ml: 2, color: 'white' }}>EDU GATE SHOP</Typography>
            </Box>
            <TextField
              placeholder="Search products..."
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
              sx={{ backgroundColor: 'white', borderRadius: 1, flexGrow: 1, mx: 2 }}
            />
          
            <IconButton onClick={handleCartOpen}>
              <Badge badgeContent={totalItemsInCart} color="secondary">
                <ShoppingCartIcon style={{ color: 'white' }} />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Container>
          <Box sx={{ display: 'flex', mb: 4, gap: 2 }}>
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel>Category</InputLabel>
              <Select
               value={category}
               onChange={handleCategoryChange}
               label="Category"
             >
               <MenuItem value=""><em>All</em></MenuItem>
               {getCategories().map((category, index) => (
                 <MenuItem key={index} value={category}>{category}</MenuItem>
               ))}
             </Select>
           </FormControl>
           <FormControl sx={{ minWidth: 180 }}>
             <InputLabel>Sort By</InputLabel>
             <Select
               value={sortOption}
               onChange={handleSortChange}
               label="Sort By"
             >
               <MenuItem value=""><em>None</em></MenuItem>
               <MenuItem value="nameAsc">Name (A-Z)</MenuItem>
               <MenuItem value="nameDesc">Name (Z-A)</MenuItem>
               <MenuItem value="priceAsc">Price (Low to High)</MenuItem>
               <MenuItem value="priceDesc">Price (High to Low)</MenuItem>
             </Select>
           </FormControl>
         </Box>
         <Grid container spacing={4}>
           {paginatedProducts.map(product => (
             <Grid item key={product._id} xs={12} sm={6} md={4} lg={3}>
               <Card sx={{ backgroundColor: '#ffffff', borderRadius: 2, boxShadow: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
                 <CardMedia
                   component="img"
                   height="180"
                   image={product.link} // Assuming 'link' is the image URL
                   alt={product.name}
                   sx={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
                 />
                 <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                   <Box>
                     <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>{product.name}</Typography>
                     <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                       {product.description.length > 50 ? (
                         <>
                           {product.description.substring(0, 50)}...
                           <Button size="small" onClick={() => handleViewMoreOpen(product)}>View More</Button>
                         </>
                       ) : (
                         product.description
                       )}
                     </Typography>
                   </Box>
                   <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                     {cart.find(item => item._id === product._id) ? (
                       <>
                         <IconButton onClick={() => handleQuantityChange(product, -1)}>
                           <RemoveIcon />
                         </IconButton>
                         <TextField
                           value={cart.find(item => item._id === product._id)?.quantity}
                           onChange={(e) => handleQuantityInputChange(product, e.target.value)}
                           type="number"
                           inputProps={{ min: 1 }}
                           sx={{ width: 80, mx: 2 }}
                         />
                         <IconButton onClick={() => handleQuantityChange(product, 1)}>
                           <AddIcon />
                         </IconButton>
                         {product.customizable === "true" && (
                           <IconButton onClick={() => handleCustomizationModalOpen(product)}>
        <EditIcon  />
                           </IconButton>
                         )}
                       </>
                     ) : (
                       product.customizable === "true" ? (
                         <Button
                           variant="contained"
                           sx={{ backgroundColor: 'darkred', color: 'white' }}
                           onClick={() => handleCustomizationModalOpen(product)}
                         >
                           Select Product
                         </Button>
                       ) : (
                         <Button
                           variant="contained"
                           sx={{ backgroundColor: 'darkred', color: 'white' }}
                           onClick={() => handleAddToCart(product)}
                         >
                           Add to Cart
                         </Button>
                       )
                     )}
                   </Box>
                 </CardContent>
               </Card>
             </Grid>
           ))}
         </Grid>
         <Pagination
           count={Math.ceil(filteredProducts.length / itemsPerPage)}
           page={page}
           onChange={(event, value) => setPage(value)}
           sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}
         />
         <Cart
           open={isCartOpen}
           handleClose={handleCartClose}
           cartItems={cart}
           setCart={setCart}
           customizationOptions={customizationOptions}
           setCustomizationOptions={setCustomizationOptions}
           customizationTexts={customizationTexts}
           setCustomizationTexts={setCustomizationTexts}
           handleCustomizationModalOpen={handleCustomizationModalOpen}
         />
       </Container>
       {selectedProduct && (
         <Dialog open={isCustomizationModalOpen} onClose={handleCustomizationModalClose}>
           <DialogTitle>{selectedProduct.name}</DialogTitle>
           <DialogContent>
             <DialogContentText>
               {selectedProduct.description}
             </DialogContentText>
             <CardMedia
               component="img"
               height="180"
               image={selectedProduct.link}
               alt={selectedProduct.name}
               sx={{ my: 2 }}
             />
             <FormControl component="fieldset" sx={{ mt: 2 }}>
               <FormControlLabel
                 control={
                   <Checkbox
                     checked={customizationOptions[selectedProduct._id]?.logo || false}
                     onChange={() => handleCustomizationOptionsChange(selectedProduct._id, 'logo')}
                   />
                 }
                 label="Add Logo"
               />
               <FormControlLabel
                 control={
                   <Checkbox
                     checked={customizationOptions[selectedProduct._id]?.text || false}
                     onChange={() => handleCustomizationOptionsChange(selectedProduct._id, 'text')}
                   />
                 }
                 label="Add Text"
               />
             </FormControl>
             {isTextSelected(selectedProduct._id) && (
               <TextField
                 label="Customization Text"
                 fullWidth
                 value={customizationTexts[selectedProduct._id] || ''}
                 onChange={(e) => handleCustomizationTextChange(selectedProduct._id, e.target.value)}
                 sx={{ mt: 2 }}
                 required
               />
             )}

{error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}
           </DialogContent>
           <DialogActions>
             <Button onClick={handleCustomizationModalClose} color="primary">
               Cancel
             </Button>
             <Button onClick={() => {
               handleAddToCart(selectedProduct);
               handleCustomizationModalClose();
             }} color="primary">
               Add to Cart
             </Button>
           </DialogActions>
         </Dialog>
       )}
       {selectedProduct && (
         <Dialog open={isViewMoreOpen} onClose={handleViewMoreClose}>
           <DialogTitle>{selectedProduct.name}</DialogTitle>
           <DialogContent>
             <DialogContentText>
               {selectedProduct.description}
             </DialogContentText>
           </DialogContent>
           <DialogActions>
             <Button onClick={handleViewMoreClose} color="primary">
               Close
             </Button>
           </DialogActions>
         </Dialog>
       )}
     </ThemeProvider>
   </Box>
 );
};

export default ProductsPage;

