import React, { useEffect, useState } from 'react';
import {
  Box,
  Drawer,
  Typography,
  List,
  ListItem,
  ListItemText,
  TextField,
  Button,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemSecondaryAction
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import axios from 'axios';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';

const theme = createTheme({
  palette: {
    primary: {
      main: '#8B0000',
    },
  },
});

const Cart = ({ open, handleClose, cartItems, setCart, customizationOptions, setCustomizationOptions, customizationTexts, setCustomizationTexts }) => {
  const [checkoutDetails, setCheckoutDetails] = useState({
    name: '',
    email: '',
    institution: '',
    phoneNumber: '',
    comment: '',
  });
  const [availableEvents, setAvailableEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [photo, setPhoto] = useState(null);
  const [error, setError] = useState('');
  const [feedback, setFeedback] = useState({
    message: '',
    type: '', // 'success' or 'error'
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [progress, setprogress] = useState(false);

  useEffect(() => {
    fetchAvailableEvents();
  }, []);

  const fetchAvailableEvents = async () => {
    try {
      const response = await axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getevent');
      const filteredEvents = response.data.filter((event) => event.deleted === '0');
      setAvailableEvents(filteredEvents);
    } catch (error) {
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCheckoutDetails({
      ...checkoutDetails,
      [name]: value,
    });
  };

  const handleQuantityChange = (product, delta) => {
    const existingProductIndex = cartItems.findIndex(item => item._id === product._id);
    if (existingProductIndex !== -1) {
      const updatedCart = [...cartItems];
      const updatedQuantity = cartItems[existingProductIndex].quantity + delta;
      if (updatedQuantity <= 0) {
        updatedCart.splice(existingProductIndex, 1);
        setCustomizationOptions(prevOptions => {
          const updatedOptions = { ...prevOptions };
          delete updatedOptions[product._id];
          return updatedOptions;
        });
        setCustomizationTexts(prevTexts => {
          const updatedTexts = { ...prevTexts };
          delete updatedTexts[product._id];
          return updatedTexts;
        });
      } else {
        updatedCart[existingProductIndex] = { ...cartItems[existingProductIndex], quantity: updatedQuantity };
      }
      setCart(updatedCart);
    }
  };

  const handleCustomizationTextChange = (productId, value) => {
    setCustomizationTexts({ ...customizationTexts, [productId]: value });
    setCart(cartItems.map(item => item._id === productId ? { ...item, customizationText: value } : item));
  };

  const handleCustomizationOptionsChange = (productId, option) => {
    setCustomizationOptions(prevOptions => {
      const updatedOptions = { ...prevOptions };
      updatedOptions[productId] = updatedOptions[productId] || {};
      updatedOptions[productId][option] = !updatedOptions[productId][option];
      return updatedOptions;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    if (!photo) {
     setError('logo cannot be left empty. upload your logo!');
    return;
 }
 if (cartItems.length === 0) {
  setError('Cart is empty, please select products before submitting!');
  return;
}


setprogress(true);

    const formData = new FormData();
    formData.append('photo', photo);

    // Append userInfo properties separately
    formData.append('eventID', selectedEvent);
    formData.append('name', checkoutDetails.name);
    formData.append('email', checkoutDetails.email);
    formData.append('institution', checkoutDetails.institution);
    formData.append('phoneNumber', checkoutDetails.phoneNumber);
    formData.append('comment', checkoutDetails.comment);
    formData.append('status', '0');

    // Append each cart item separately
    cartItems.forEach((item, index) => {
      formData.append(`cart[${index}][productID]`, item._id);
      formData.append(`cart[${index}][quantity]`, item.quantity);
      formData.append(`cart[${index}][customizationText]`, item.customizationText);
      formData.append(`cart[${index}][customizationOptions]`, JSON.stringify(item.customizationOptions));
    });

    try {
      setIsSubmitting(true);
      const response = await axios.post('https://edutech-backend-471f7cb69116.herokuapp.com/api/addcart', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 201) {
        setFeedback({
          message: 'Your Order was Submitted, Our Team will Reach you Soon!. Thank You for Choosing EDU GATE',
          type: 'success',
        });
      } else {
        setFeedback({
          message: 'Error submitting the form',
          type: 'error',
        });
      }
    } catch (error) {
      setFeedback({
        message: 'Error submitting the form',
        type: 'error',
      });
    }
    setprogress(false);

  };

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validImageTypes = ['image/jpeg', 'image/png'];
      if (!validImageTypes.includes(file.type)) {
        setError('Please upload a valid image file (JPEG, PNG)');

        setPhoto(null);
        return;
      }
      const maxSizeBytes = 2 * 1024 * 1024; // 2MB in bytes
    if (file.size > maxSizeBytes) {
      setError('File size exceeds the limit of 2MB. Please choose a smaller file.');

      setPhoto(null);
      return;
    }

      // Read the file to check if it's a valid image
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          // File is valid
          const extension = file.name.split('.').pop();
          const newName = `edugate_checkout_${Date.now()}.${extension}`;
          const renamedFile = new File([file], newName, { type: file.type });
          setPhoto(renamedFile);
        };
        img.onerror = () => {
          setError('The uploaded file is not a valid image or is corrupted');

          setPhoto(null);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <Box sx={{ width: 350, p: 2 }}>
        <Typography variant="h6">Cart Summary</Typography>
        <List>
          {cartItems.map((item, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={item.name}
                secondary={
                  <>
                    Quantity: {item.quantity}
                    <br />
                    {item.customizationText && `Customization: ${item.customizationText}`}
                    {((item.customizationOptions && item.customizationOptions.logo) || (item.customizationOptions && item.customizationOptions.text)) && (
                      <>
                        <div>
                          Add Logo: {item.customizationOptions && item.customizationOptions.logo ? 'Yes' : 'No'}
                        </div>
                        <div>
                          Add Text: {item.customizationOptions && item.customizationOptions.text ? 'Yes' : 'No'}
                        </div>
                      </>
                    )}
                  </>
                }
              />
              <IconButton onClick={() => handleQuantityChange(item, -1)}>
                <RemoveIcon />
              </IconButton>
              <Typography>{item.quantity}</Typography>
              <IconButton onClick={() => handleQuantityChange(item, 1)}>
                <AddIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
        <Typography variant="h6" sx={{ mt: 2 }}>
          Checkout
        </Typography>
        <form encType="multipart/form-data" onSubmit={handleSubmit}>
          <ThemeProvider theme={theme}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Select Event</InputLabel>
              <Select
                label="Select Event"
                value={selectedEvent}
                onChange={(e) => setSelectedEvent(e.target.value)}
                required
              >
                <MenuItem value="">
                  <em>Select an event</em>
                </MenuItem>
                {availableEvents.map((event) => (
                  <MenuItem key={event.id} value={event.id}>
                    {event.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Name"
              name="name"
              value={checkoutDetails.name}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Email"
              name="email"
              value={checkoutDetails.email}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              type="email"
            />
            <TextField
              label="Institution"
              name="institution"
              value={checkoutDetails.institution}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Phone Number"
              name="phoneNumber"
              value={checkoutDetails.phoneNumber}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              type="tel"
            />
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="upload-photo"
              type="file"
              onChange={handlePhotoUpload}
            />
            <label htmlFor="upload-photo">
              <Button
                startIcon={<CloudUploadIcon />}
                variant="contained"
                component="span"
                sx={{ mt: 2 }}
              >
                Upload Logo
              </Button>
            </label>
            {photo && <Typography sx={{ mt: 1 }}>{photo.name}</Typography>}
            
            <TextField
              id="outlined-textarea"
              label="If you have any enquires or to customize a specific product write here please all your wishes"
              placeholder="Any Enquiries"
              multiline
              onChange={handleChange}
              name="comment"
              value={checkoutDetails.comment}
              fullWidth
              margin="normal"
              sx={{ mt: 2 }}
            />
            {feedback.message && (
              <Typography
                variant="body1"
                style={{ color: feedback.type === 'error' ? 'red' : 'green' }}
                sx={{ mt: 1 }}
              >
                {feedback.message}
              </Typography>
            )}

{error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}
            <Button
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              Submit Order
            </Button>
            {progress && (
    <Box sx={{ width: '100%' }}>
    <LinearProgress />
                    </Box>
                )}
          </ThemeProvider>
        </form>
      </Box>
    </Drawer>
  );
};

export default Cart;
