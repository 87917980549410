import React, { useState, useEffect, useRef } from 'react';
import {
  IconButton,
  Badge,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Divider,
  Box,
  Modal,
  Card,
  CardMedia,
  CardContent,
  Tooltip,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import SnoozeIcon from '@mui/icons-material/Snooze';
import InfoIcon from '@mui/icons-material/Info';
import './NotificationMenu.css'; // Import the CSS file

const NotificationMenu = ({ username }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [previousNotifications, setPreviousNotifications] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [animate, setAnimate] = useState(false);
  const [snoozedNotifications, setSnoozedNotifications] = useState({});
  
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await fetch(`https://edutech-backend-471f7cb69116.herokuapp.com/api/notifications?username=${username}`);
        if (response.ok) {
          const data = await response.json();
          const newNotifications = data.notifications.filter(noti => noti.status === "not done");
          
          // Filter out snoozed notifications
          const validNotifications = newNotifications.filter(noti => !snoozedNotifications[noti._id]);
  
          const previousIds = new Set(previousNotifications.map(noti => noti._id));
          
          const hasNewNotifications = validNotifications.some(noti => !previousIds.has(noti._id));
          
          if (hasNewNotifications) {
            setAnimate(true);
          }
  
          // Update the states
          setPreviousNotifications(validNotifications);
          setNotifications(validNotifications);
        } else {
        }
      } catch (error) {
      }
    };
  
    fetchNotifications();
  
    const intervalId = setInterval(fetchNotifications, 60000);
  
    return () => clearInterval(intervalId);
  }, [username, snoozedNotifications, previousNotifications]);

  const handleSnooze = (event, notification) => {
    event.stopPropagation();
   // console.log(`Snoozing notification: ${notification.title}`);
  
    const snoozeId = notification._id;
    setNotifications(prev => prev.filter(n => n._id !== snoozeId));

    setSnoozedNotifications(prev => ({
      ...prev,
      [snoozeId]: true,
    }));

    setTimeout(() => {
      setSnoozedNotifications(prev => {
        const { [snoozeId]: _, ...rest } = prev;
        return rest;
      });

      // After 5 minutes, either re-fetch or re-add the snoozed notification
      setNotifications(prev => [...prev, notification]);
    }, 300000); // 5 minutes
  }

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setAnimate(false); 
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenModal = (notification) => {
    setSelectedNotification(notification);
    handleCloseMenu();
  };

  const handleCloseModal = () => {
    setSelectedNotification(null);
  };

  const handleMarkAsDone = async (event, notification) => {
    event.stopPropagation();
    //console.log(`Marking notification as done: ${notification.title}`);
    
    try {
      const response = await fetch(`https://edutech-backend-471f7cb69116.herokuapp.com/api/notifications/${notification._id}/done`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username }),
      });

      if (response.ok) {
        setNotifications((prev) =>
          prev.filter((n) => n._id !== notification._id)
        );
      } else {
       // console.error('Failed to mark notification as done.');
      }
    } catch (error) {
      //console.error('Error marking notification as done:', error);
    }
  };

  return (
    <Box>
      <IconButton color="inherit" onClick={handleOpenMenu}>
        <Badge badgeContent={notifications.length} color="secondary" className={animate ? 'notification-shake' : ''}>
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            width: '500px',
            maxHeight: '50vh',
            overflowY: 'auto',
            marginTop: '10px',
            boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
          },
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold', margin: 1 }}>
          Notifications
        </Typography>
        <Divider />
        {notifications.length === 0 ? (
          <Typography variant="body2" color="textSecondary" align="center" sx={{ marginTop: 2 }}>
            No new notifications
          </Typography>
        ) : (
          notifications.map((notification, index) => (
            <MenuItem
              key={index}
              onClick={() => handleOpenModal(notification)}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                padding: 2,
                borderRadius: 1,
                transition: 'background-color 0.3s ease',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.08)',
                  cursor: 'pointer',
                },
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                <ListItemIcon sx={{ minWidth: 48 }}>
                  <img
                    src={notification.image}
                    alt={notification.title}
                    style={{ width: 40, height: 40, borderRadius: '50%' }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={notification.title}
                  secondary={notification.description}
                  primaryTypographyProps={{
                    sx: { fontWeight: 'bold' },
                  }}
                  sx={{
                    whiteSpace: 'normal',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                  }}
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Tooltip title="Mark as Done">
                  <IconButton
                    edge="end"
                    color="primary"
                    onClick={(event) => handleMarkAsDone(event, notification)}
                  >
                    <DoneIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Snooze">
                  <IconButton
                    edge="end"
                    color="secondary"
                    onClick={(event) => handleSnooze(event, notification)}
                  >
                    <SnoozeIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="See Details">
                  <IconButton edge="end" onClick={() => handleOpenModal(notification)}>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </MenuItem>
          ))
        )}
      </Menu>

      <Modal
        open={!!selectedNotification}
        onClose={handleCloseModal}
        aria-labelledby="notification-modal-title"
        aria-describedby="notification-modal-description"
      >
        <Card
          sx={{
            width: 400,
            margin: '100px auto',
            position: 'relative',
            boxShadow: 24,
            padding: 2,
          }}
        >
          <IconButton
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          {selectedNotification && (
            <>
              <CardMedia
                component="img"
                height="140"
                image={selectedNotification.image}
                alt={selectedNotification.title}
              />
              <CardContent>
                <Typography variant="h5" id="notification-modal-title" sx={{ fontWeight: 'bold' }}>
                  {selectedNotification.title}
                </Typography>
                <Typography variant="body1" id="notification-modal-description" sx={{ marginTop: 1 }}>
                  {selectedNotification.fullDescription || selectedNotification.description}
                </Typography>
              </CardContent>
            </>
          )}
        </Card>
      </Modal>
    </Box>
  );
};

export default NotificationMenu;
